import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import AboutHeader from "../About/components/AboutHeader";
import AboutConent from "../About/components/AboutConent";
import VisionandMission from "../About/components/VisionandMission";
import Services from "../../../assets/images/Services.jpg";
import UseCases from "../components/UseCases";
import useLocale from "../../../../utility/useLocal";


const DashboardServices = () => {
  const {
    Services:Heading,
    WhatMakesUsDifferent,
    CoreCommitment,
    CommitmentToInnovation,
    CustomerCentricApproach,
    Focus,
    KeyFeatures,
    SMSCertificate,
    SMSCertificate_Content,
    EmailCertificate,
    EmailCertificate_Content,
    MessageAuthentication,
    MessageAuthentication_Content,
    IncreasedDeliverability,
    IncreasedDeliverability_Content,
    SecurityMeasures,
    SecurityMeasures_Content,
    ScalabilityAndReliability,
    ScalabilityAndReliability_Content,
  } = useLocale();
  return (
    <PageWrapper>
      <AboutHeader heading={Heading} />
      <AboutConent
        style={{
          height: "80vh",
        }}
        heading={WhatMakesUsDifferent}
        image={Services}
        content={[
          CoreCommitment,
          CommitmentToInnovation,
          CustomerCentricApproach,
          Focus,
        ]}
      />
      <VisionandMission
        data={[
          {
            title: SMSCertificate,
            content: SMSCertificate_Content,
          },
          {
            title: EmailCertificate,
            content: EmailCertificate_Content,
          },
        ]}
      />
      <UseCases
        heading={KeyFeatures}
        data={[
          {
            title: MessageAuthentication,
            content: MessageAuthentication_Content,
          },
          {
            title: IncreasedDeliverability,
            content: IncreasedDeliverability_Content,
          },
          {
            title: SecurityMeasures,
            content: SecurityMeasures_Content,
          },
          {
            title: ScalabilityAndReliability,
            content: ScalabilityAndReliability_Content,
          },
        ]}
      />
    </PageWrapper>
  );
};



export default DashboardServices;
