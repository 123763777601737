import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination } from '@mui/material'; // Ensure all components are imported
import apiCall from '../../../API/apiService';
import apiEndpoints from '../../../API/apiEndpoints';
import Loader from '../../../components/Loader';

const ResponsiveTable = ({ initialRows }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8); // Default rows per page

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Promo Code</TableCell>
                            <TableCell>Consultant Email</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {initialRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.promo_code}</TableCell>
                                <TableCell>{row.consultant_email}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.last_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[8, 10, 25]} // Options for rows per page
                component="div"
                count={initialRows.length} // Total number of rows
                rowsPerPage={rowsPerPage} // Current rows per page
                page={page} // Current page
                onPageChange={handleChangePage} // Page change handler
                onRowsPerPageChange={handleChangeRowsPerPage} // Rows per page change handler
            />
        </Paper>
    );
};

const ReferralCodeTracker = () => {
    const [loading, setLoading] = useState(true);
    const [referralData, setReferralData] = useState([]);
    const currentDate = new Date();
    const lastWeekDate = new Date();
    lastWeekDate.setDate(currentDate.getDate() - 7); // Subtract 7 days

    const formatDate = (date) => {
        return date.toISOString().replace('T', ' ').substring(0, 19); // Format to "YYYY-MM-DD HH:mm:ss"
    };

    const [fromDate, setFromDate] = useState(formatDate(lastWeekDate)); // Set to last week's date
    const [toDate, setToDate] = useState(formatDate(currentDate)); // Set to current date
    const initialFromDate = formatDate(lastWeekDate); // Set to last week's date
    const initialToDate = formatDate(currentDate); // Set to current date
    const [email, setEmail] = useState("");

    useEffect(() => {
        fetchUsersData();
    }, []);

    const fetchUsersData = async () => {
        setLoading(true);
        try {
            const fetchData = async (endpoint) => {
                const raw = {
                    "consultant_id": email, // Consultant emailId
                    "from_date": fromDate.replace('T', ' '), // Ensure T is replaced with space
                    "to_date": toDate.replace('T', ' ') // Ensure T is replaced with space
                };
                const result = await apiCall("POST", endpoint, raw);
                return result?.response || [];
            };

            const [referralUsers] = await Promise.all([fetchData(apiEndpoints.REFERRAL_CODE_TRACKER)]);
            setReferralData(referralUsers);
        } catch (error) {
            console.error("Error fetching referral data:", error); // Log the error
        } finally {
            setLoading(false); // Ensure loading is set to false regardless of success or failure
        }
    };

    const clearFilters = () => {
        setFromDate(initialFromDate);
        setToDate(initialToDate);
        fetchUsersData();
    };

    return (
        <Box sx={{ width: '100%', p: 1, bgcolor: '#f5f5f5', borderRadius: 4, height: '86vh', overflow: 'scroll' }}>
            <Box sx={{
                bgcolor: '#ffffff',
                p: 1,
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    bgcolor: '#ffffff',
                    p: 0.6,
                    px: 1,
                    borderRadius: 2,
                    boxShadow: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <TextField
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Consultant Email..."
                        variant="outlined"
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#fe6c00', // Sets default border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#fe6c00', // Sets border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#fe6c00', // Sets border color when focused
                                },
                            },
                        }}
                    />
                    <TextField
                        type="datetime-local"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#fe6c00', // Sets default border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#fe6c00', // Sets border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#fe6c00', // Sets border color when focused
                                },
                            },
                        }}
                    />
                    <TextField
                        type="datetime-local"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#fe6c00', // Sets default border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#fe6c00', // Sets border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#fe6c00', // Sets border color when focused
                                },
                            },
                        }}
                    />
                </Box>
                <Box sx={{
                    bgcolor: '#ffffff',
                    p: 0.6,
                    px: 1,
                    borderRadius: 2,
                    boxShadow: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={fetchUsersData}
                        sx={{ bgcolor: '#fe6c00', '&:hover': { bgcolor: '#e65c00' } }}
                        size='small'
                    >
                        Apply Filter
                    </Button>
                    <Button
                        variant="contained"
                        color=""
                        onClick={clearFilters}
                        sx={{ bgcolor: '#ffffff', '&:hover': { bgcolor: '#f0f0f0' } }}
                        size='small'
                    >
                        Clear Filter
                    </Button>
                </Box>
            </Box>
            <Box sx={{ py: 2 }}>
                {loading ? (
                    <Loader />
                ) : (
                    <ResponsiveTable initialRows={referralData} />
                )}
            </Box>
        </Box>
    );
};

export default ReferralCodeTracker;