import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ComposeMail.css"; // Import the CSS file
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../components/Loader";
import { toast } from "react-hot-toast";
import cashfree from "../../../util";
import { GetDraftData, GetPaymentStatus } from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import Captcha from "./Captcha";
import { Stepper, Step } from "react-form-stepper";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Redirection_url, ROOT } from "../../API/AppUrl";
const ComposeMail = () => {
	const { settingData, locale } = useSelector((state) => state.user);
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const isiPadPro = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const generateCaptcha = () => {
		const chars =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let captcha = "";
		for (let i = 0; i < 6; i++) {
			captcha += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return captcha;
	};
	const { state } = useLocation();
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [email, setEmail] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [body, setBody] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [captcha, setCaptcha] = useState(generateCaptcha());
	const [userInput, setUserInput] = useState("");
	const [activeStep, setActiveStep] = useState(1);
	const [isDraft, setDraft] = useState(false)
	const [statusData, setStatusData] = useState({
		isFailed: false,
		statusName: "",
	});
	const [bsa, setBSA] = useState(false);
	const [logs, setLOGS] = useState(false);
	const [copyMail, setCopyMail] = useState(false)
	const navigate = useNavigate();
	const { id } = state || {};
	useEffect(() => {
		getDraftData(id);
	}, [id]);
	useEffect(() => {
		if (!settingData?.mob_verify) {
			toast.error("Verify phone number to proceed!", {
				duration: 2000
			});
		}
	}, [])

	const getDraftData = (id) => {
		if (id) {
			GetDraftData(id).then((response) => {
				setLoading(false);
				console.log(response, "response");
				if (response?.response == "ok") {
					setPhone(response?.draft_data?.mob);
					setSubject(response?.draft_data?.subject);
					setEmail(response?.draft_data?.recipient);
					setDraft(true)
					const validJsonString = response?.draft_data?.attachment?.replace(
						/'/g,
						'"'
					);
					const parsedArray = JSON.parse(validJsonString);
					setAttachments(parsedArray);

					setBody(response?.draft_data?.body);
				} else {
					toast.error("Something went wrong!");
				}
			});
		}
	};
	const handleFileChange = async (event) => {
		const newFiles = Array.from(event.target.files); // Convert FileList to an array

		const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0); // Calculate the size of new files only

		const existingFilesSize = attachments.reduce(
			(sum, file) => sum + file.size,
			0
		); // Get the size of already stored files
		const combinedSize = totalSize + existingFilesSize; // Total combined size

		if (combinedSize > 5 * 1024 * 1024) {
			// Check if the combined size exceeds 5MB
			toast.error("Total file size should be less than 5MB");
			return;
		} else {
			try {
				const filePromises = newFiles.map((file) => convertToBase64(file)); // Convert only new files to Base64
				const base64Files = await Promise.all(filePromises); // Wait for all conversions to complete

				const formattedFiles = base64Files.map((base64, index) => ({
					name: newFiles[index].name,
					data: `data:${newFiles[index]?.type};base64,${base64}`,
				}));
				console.log(JSON.stringify(formattedFiles), "formattedFiles");
				// Update the state with new attachments
				setAttachments((prevAttachments) => [
					...prevAttachments,
					...formattedFiles,
				]);
			} catch (error) {
				console.error("Error converting files to Base64:", error);
				toast.error("An error occurred while processing files.");
			}
		}
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result.split(",")[1]); // Get the Base64 part of the data URL
			reader.onerror = (error) => reject(error);
		});
	};

	const handleRemoveFile = (index) => {
		const updatedAttachments = [...attachments]; // Copy the current attachments array
		updatedAttachments.splice(index, 1); // Remove the file at the specified index
		setAttachments(updatedAttachments); // Update the state with the new array
	};

	// Handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault();
		const res = validateCaptcha();
		if (!res) return null;
		console.log({ phone, subject, attachments, body });
		setLoading(true);
		const orderId = uuidv4()
		const jsonObject = {
			purpose: "1",
			// orderid: orderId,
			...(isDraft ? { orderid: id } : {}),
			return_url: `${Redirection_url}/compose-email${isDraft ? `?orderId=` : '?orderId='}`,
			notify_url: `${Redirection_url}/compose-email${isDraft ? `?orderId=` : '?orderId='}`,
			// return_url: `${Redirection_url}/compose-email?orderId=${orderId}`,
			// notify_url: `${Redirection_url}/compose-email?orderId=${orderId}`,
			To_mail: email,
			mobile: phone,
			subject: subject,
			mail_body: body,
			attachments: attachments,
			bsa: bsa,
			logs: logs,
			copy_mail: copyMail
		  };		  
		  
		console.log(JSON.stringify(jsonObject, null, 4));
		const token = await localStorage.getItem("key");
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(jsonObject),
		};

		try {
			const response = await fetch(
				`${ROOT}/beforePayment/`,
				requestOptions
			);
			const contentType = response.headers.get("Content-Type");
			const rawText = await response.text();
			console.log("Raw Response:", rawText);

			let result = contentType.includes("application/json")
				? JSON.parse(rawText)
				: { response: rawText };

			if (result?.order_id) {
				handleRedirect(result.id);
			}
		} catch (error) {
			console.log("Submission Error:", error);
		} finally {
			setLoading(false);
		}
		// Handle form submission logic here, such as making an API call
	};

	const handleRedirect = (session_id) => {
		let paymentOptions = {
			paymentSessionId: session_id,
		};
		cashfree.checkout(paymentOptions).then((result) => {
			console.log(result);
		});
	};
	const handleInputChange = (e) => {
		setUserInput(e.target.value);
	};

	// Step 4: Function to validate CAPTCHA
	const validateCaptcha = () => {
		if (userInput === captcha) {
			return true;
		} else {
			toast.error("CAPTCHA is incorrect. Try again!");
			return false;
		}
	};

	// Step 5: Function to refresh the CAPTCHA
	const refreshCaptcha = () => {
		setCaptcha(generateCaptcha());
		setUserInput("");
	};
	const resetForm = () => {
		setPhone("");
		setSubject("");
		setEmail("");
		setAttachments([]);
		setBody("");
		setLoading(false);
	};

	useEffect(() => {
		const url = window.location.href;
		const orderId = url.split("orderId=")[1];
		if (orderId) {
			getPaymentDetails(orderId);
		}
	}, []);

	const getPaymentDetails = (orderId) => {
		setLoading(true);
		try {
			GetPaymentStatus(orderId).then((response) => {
				setLoading(false);
				if (response.order_info?.order_status) {
					resetForm();
					setActiveStep(5);
					setStatusData(() => ({
						isFailed: false,
						statusName: "Success",
					}));
					toast.success("Payment successfully done \n" + response?.resp);
					setTimeout(() => {
						setActiveStep(2);
						setStatusData({
							isFailed: false,
							statusName: "",
						});
						navigate("/compose-email");
					}, 3000);
				} else {
					setActiveStep(5);
					setStatusData(() => ({
						isFailed: true,
						statusName: "Failed",
					}));
					setTimeout(() => {
						setActiveStep(2);
						setStatusData({
							isFailed: false,
							statusName: "",
						});
						navigate("/compose-email");
					}, 3000);
					toast.error("Something went wrong! \n" + response?.resp);
				}
			});
		} catch (error) {
			toast.error(JSON.stringify(error));
		} finally {
			setLoading(false);
		}
	};
	const checkBoxComponent = () => {
		return (
			<>
				<span style={{ color: "rgba(0,0,0,0.8)", fontSize: 14, fontWeight:'600' }}>
					Would you like to:
				</span>
				<div style={{ display: 'flex', margin:'4px 0', flexDirection:isMobile?'column':'row' }}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={bsa}
							onChange={()=>setBSA(!bsa)}
							style={{ marginRight: 5 }}
						/>
						<span title="Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes the significance of electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence." style={{ color: "gray", fontSize: 14, }}>
							Include certificate in 63BSA format?
						</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={logs}
							onChange={()=>setLOGS(!logs)}
							style={{ marginRight: 5 }}
						/>
						<span style={{ color: "gray", fontSize: 14, }}>
							Add additional logs to certificate?
						</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={copyMail}
							onChange={()=>setCopyMail(!copyMail)}
							style={{ marginRight: 5 }}
						/>
						<span style={{ color: "gray", fontSize: 14, }}>
							Receive a copy of the certified communication?
						</span>
					</div>
				</div>
			</>

		)
	}
	return (
		<div className="profile-page-container">
			<div className="profile-page-section">
				<span>Compose Email</span>
			</div>
			{isLoading && <Loader />}
			<div className="form-container">
				<form onSubmit={handleSubmit}>
					<div style={{ height: "68vh", overflow: "scroll" }}>
						<Stepper
							activeStep={activeStep}
							connectorStateColors={true}
							connectorStyleConfig={{
								completedColor:
									activeStep == 5 && statusData.isFailed ? "red" : "#14619C",
								activeColor: "#14619C",
								disabledColor: "rgba(0,0,0,0.2)",
							}}
							styleConfig={{
								activeBgColor:
									activeStep == 4 && statusData.isFailed ? "red" : "#ec9138",
								completedBgColor: "#14619C",
								inactiveBgColor: "#eee",
								activeTextColor: "#fff",
								completedTextColor: "#fff",
								inactiveTextColor: "#444",
							}}
						>
							<Step label="Login" />
							<Step label="Compose" />
							<Step label="Payment" />
							<Step
								label={
									activeStep == 5 && statusData.isFailed
										? "Failed"
										: activeStep == 5 && !statusData.isFailed
											? "Submitted"
											: "Submit"
								}
							/>
						</Stepper>
						<div
							style={{
								display: "flex",
								width: "100%",
								flexDirection: isMobile ? "column" : "row",
							}}
						>
							<div style={{ width: isMobile ? "100%" : "40%" }}>
								<div className="form-group">
									<label className="form-label">
										Recipient's mobile number:
									</label>
									<input
										type="number"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Enter_Recipient_Contact}
										maxLength={10}
										style={{ width: "96%" }}
									/>
								</div>
								{/* Email Field */}
								<div className="form-group">
									<label className="form-label">Recipient's email:</label>
									<input
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Enter_Recipient_Email}
										style={{ width: "96%" }}
									/>
								</div>
								{/* Subject Field */}
								<div className="form-group">
									<label className="form-label">Subject:</label>
									<input
										type="text"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Subject}
										style={{ width: "96%" }}
									/>
								</div>

								<div className="form-group">
									<label className="form-label">
										Attachments (Max 5MB total):
									</label>
									{/* Attachments Field */}
									<div className="file-list" style={{ width: "97.6%" }}>
										{attachments.map((file, index) => (
											<div key={index} className="file-item">
												<span>{file.name}</span>
												<button
													type="button"
													className="remove-file-button"
													onClick={() => handleRemoveFile(index)}
												>
													&times; {/* Cross icon */}
												</button>
											</div>
										))}
									</div>
									<label htmlFor="file-input" className="file-upload-label">
										Choose Files
									</label>
									<input
										id="file-input"
										type="file"
										multiple
										onChange={handleFileChange}
										className="form-input-file"
										accept="*" // Adjust as needed
										style={{ display: "none" }}
									/>
								</div>
								{!isMobile && (
									<Captcha
										captcha={captcha}
										refreshCaptcha={refreshCaptcha}
										userInput={userInput}
										handleInputChange={handleInputChange}
									/>
								)}
							</div>
							<div
								style={{
									flexDirection: "column",
									width: isMobile ? "92%" : "58%",
								}}
							>
								{/* Rich Text Editor for Body Field */}
								<div className="form-group">
									<label className="form-label">Body:</label>
									<ReactQuill
										theme="snow"
										value={body}
										onChange={setBody}
										className="form-editor"
										style={{ width: "100%" }}
									/>
								</div>
								{isMobile && (
									<Captcha
										captcha={captcha}
										refreshCaptcha={refreshCaptcha}
										userInput={userInput}
										handleInputChange={handleInputChange}
									/>
								)}
								{checkBoxComponent()}
								<div className="form-group">
									<button type="submit" className="form-button">
										Submit
									</button>
									<p style={{ color: "gray", margin:0, marginTop:4}}>
										*Please note: A transaction fee of{" "}
										<strong style={{ color: "#000" }}>₹1</strong> will be
										applied upon submission.
									</p>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ComposeMail;
