export const PrivacyData={
    "title": "Privacy Policy",
    "lastUpdated": "14th Jul 2023",
    "sections": [
      {
        "title": "1. Data Storage and Liability",
        "content": [
          "M/S Synerthink Solutions LLP stores the data received through this Secured portal but cannot take any responsibility or liability if the same is leaked out from our server by a means beyond our industry standard security measures.",
          "M/S Synerthink Solutions LLP can use the received data for our internal TRAFFIC analysis.",
          "M/s Synerthink Solutions LLP is authorized by you to send mail and/or SMS on your behalf, registered clients, and the given authorization overrides any restrictions imposed by recipient in any form including the kind of restriction offered through imposition by regulatory agencies like TRAI etc for sending electronic communications.",
          "In case of breach of rules and regulations by the clients encountered, M/S Synerthink Solutions LLP cannot be made liable for the breach committed by the Client or by recipient. and as a consequence, the service will be blocked for that client."
        ]
      },
      {
        "title": "2. Cookie Policy",
        "lastUpdated": "14th Jul 2023",
        "content": "The statements included herein disclose the cookie practices (\"Cookie Policy\") of the Website https://www,certifi.biz to help visitors to understand how and why cookies are saved and used to increment/enhance the visitors experience of the Website.",
        "subsections": [
          {
            "title": "2.1 What is a cookie?",
            "content": "Cookie is small web file that a web browser creates and stores in the device used by a visitor either temporarily or permanently in order to make a web page more responsive to the requirements of such visitor."
          },
          {
            "title": "2.2 What are the cookies used by the Website?",
            "content": "We use the following categories of cookies:",
            "bullets": [
              "Essential cookies: We create and store cookies the moment you log in to our Website. These cookies are used to manage your login sessions. These cookies are typically removed or cleared when you log out in order to ensure that you can only access such features and areas when you are logged in."
            ]
          },
          {
            "title": "2.3 Do these cookies collect personal data/identify me?",
            "content": "Most types of cookies track consumers via their device id or internet protocol (\"IP\") address therefore they may collect personal data. Based on the type of cookies used by the relevant third-party, the information these cookies collect may include personal data but they may not be able to directly identify you as an individual or have any of your personal information such as name, e-mail id or mobile number."
          },
          {
            "title": "2.4 Can you control cookies collected on this Website?",
            "content": "If you do not want cookies to be created and stored on your device, there are some options available to help you anonymously visit websites, including the possibility to set your web browser to block all cookies or to initiate an anonymous browsing session.",
            "bullets": [
              "You can also delete cookies after having visited our Website.",
              "If you browse our Website using your web browser's anonymous visitor setting (called \"Incognito\" in Chrome, \"InPrivate\" in Internet Explorer etc.), cookies cannot be set on your device.",
              "All major web browsers offer security provisions to block all or some specific or certain third party cookies.",
              "You may also activate such settings on your web browser that may allow you to refuse cookies."
            ]
          }
        ]
      },
      {
        "title": "3. Acceptance of Terms",
        "content": "This privacy policy governs the manner in which Synerthink Solutions LLP collects, uses, maintains and discloses information collected from users (each, a \"User\") of the https://www.certifi.biz website (\"Website\"). This privacy policy applies to the Website and all services offered by Synerthink Solutions LLP."
      },
      {
        "title": "4. Personal Identification Information",
        "content": "We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Website, registers on the Website, place an order and in connection with other activities, services, features or resources we make available on our Website.",
        "bullets": [
          "Users may be asked for, as appropriate, name, email address, mailing address, phone number.",
          "Users may, however, visit our Website anonymously.",
          "We will collect personal identification information from Users only if they voluntarily submit such information to us.",
          "Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Website related activities.",
          "Data like mail ids, phone numbers and time of communication get stored as CDR only for billing purposes.",
          "The CDRs are retained to the specified period of time to meet the compliance of statutory bodies of India."
        ]
      },
      {
        "title": "5. How We Use Collected Information",
        "content": "All data will be collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes. We will ensure that the collection, use and disclosure of any personal data that we receive from you pursuant to your use of the Website/Platform will be kept in strict accordance with all applicable laws. Machynworks may collect and use Users personal information for the following purposes:",
        "subsections": [
          {
            "title": "5.1 As part of our service:",
            "bullets": [
              "The IP address from where the User access the system for making the agreement is recorded for addressing any future dispute.",
              "Personal information of the User such as the name, mail id, phone number are collected for creating digital proof of delivery certification.",
              "Location coordinates, altitude, IP address of the system used by the User to access the Website, phone model is recorded for creating digital proof of existence certificate.",
              "The information collected as mentioned above in clause 5.1(a)(i) and clause 5.1(a)(ii) shall only be used for compliant resolution and for performance improvement.",
              "The communication which intended to be processed for certification is to be received from registered clients and stored in our system for forward transmission and delivery certification in line with concept of this solution.",
              "Recipient's concerned data will be used for generating certificate and will be used as a part of mandatory information in order to make a proof of delivery having legal sanctity.",
              "All readable data of the communications made by the User will be deleted from the system in 24 hours before which the User will need to download the certificates if needed.",
              "The generated certificates are stored only in non-readable format which is HASH for future cross verification of issued certificates by User."
            ]
          },
          {
            "title": "5.2 Other purposes:",
            "bullets": [
              "To personalize user experience – We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.",
              "To improve our Website – We may use feedback you provide to improve our products and services.",
              "To send periodic emails – We may use the email address stored in our marketing database to send User information and updates pertaining to their services.",
              "To run a promotion, contest, survey or other Website feature – To send Users information they agreed to receive about topics we think will be of interest to them."
            ]
          }
        ]
      }
    ],
    "contactInformation": {
      "company": "SYNERTHINK SOLUTIONS LLP",
      "email": "vcare@mail.machynworks.com"
    }
  }


  export const TermsandConditions={
    "title": "Terms and Conditions",
    "lastUpdated": "14th Jul 2023",
    "sections": [
      {
        "title": "1. Data Storage and Liability",
        "content": [
          "M/S Synerthink Solutions LLP stores the data received through this Secured portal but cannot take any responsibility or liability if the same is leaked out from our server by a means beyond our industry standard security measures.",
          "M/S Synerthink Solutions LLP can use the received data for our internal TRAFFIC analysis.",
          "M/s Synerthink Solutions LLP is authorized by you to send mail and/or SMS on your behalf, registered clients, and the given authorization overrides any restrictions imposed by recipient in any form including the kind of restriction offered through imposition by regulatory agencies like TRAI etc for sending electronic communications.",
          "In case of breach of rules and regulations by the clients encountered, M/S Synerthink Solutions LLP cannot be made liable for the breach committed by the Client or by recipient. and as a consequence, the service will be blocked for that client."
        ]
      },
      {
        "title": "2. Cookie Policy",
        "lastUpdated": "14th Jul 2023",
        "content": "The statements included herein disclose the cookie practices (\"Cookie Policy\") of the Website https://www,certifi.biz to help visitors to understand how and why cookies are saved and used to increment/enhance the visitors experience of the Website.",
        "subsections": [
          {
            "title": "2.1 What is a cookie?",
            "content": "Cookie is small web file that a web browser creates and stores in the device used by a visitor either temporarily or permanently in order to make a web page more responsive to the requirements of such visitor."
          },
          {
            "title": "2.2 What are the cookies used by the Website?",
            "content": "We use the following categories of cookies:",
            "bullets": [
              "Essential cookies: We create and store cookies the moment you log in to our Website. These cookies are used to manage your login sessions. These cookies are typically removed or cleared when you log out in order to ensure that you can only access such features and areas when you are logged in."
            ]
          },
          {
            "title": "2.3 Do these cookies collect personal data/identify me?",
            "content": "Most types of cookies track consumers via their device id or internet protocol (\"IP\") address therefore they may collect personal data. Based on the type of cookies used by the relevant third-party, the information these cookies collect may include personal data but they may not be able to directly identify you as an individual or have any of your personal information such as name, e-mail id or mobile number."
          },
          {
            "title": "2.4 Can you control cookies collected on this Website?",
            "content": "If you do not want cookies to be created and stored on your device, there are some options available to help you anonymously visit websites, including the possibility to set your web browser to block all cookies or to initiate an anonymous browsing session.",
            "bullets": [
              "You can also delete cookies after having visited our Website.",
              "If you browse our Website using your web browser's anonymous visitor setting (called \"Incognito\" in Chrome, \"InPrivate\" in Internet Explorer etc.), cookies cannot be set on your device.",
              "All major web browsers offer security provisions to block all or some specific or certain third party cookies.",
              "You may also activate such settings on your web browser that may allow you to refuse cookies."
            ]
          }
        ]
      },
      {
        "title": "3. Acceptance of Terms",
        "content": "This privacy policy governs the manner in which Synerthink Solutions LLP collects, uses, maintains and discloses information collected from users (each, a \"User\") of the https://www.certifi.biz website (\"Website\"). This privacy policy applies to the Website and all services offered by Synerthink Solutions LLP."
      },
      {
        "title": "4. Personal Identification Information",
        "content": "We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Website, registers on the Website, place an order and in connection with other activities, services, features or resources we make available on our Website.",
        "bullets": [
          "Users may be asked for, as appropriate, name, email address, mailing address, phone number.",
          "Users may, however, visit our Website anonymously.",
          "We will collect personal identification information from Users only if they voluntarily submit such information to us.",
          "Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Website related activities.",
          "Data like mail ids, phone numbers and time of communication get stored as CDR only for billing purposes.",
          "The CDRs are retained to the specified period of time to meet the compliance of statutory bodies of India."
        ]
      },
      {
        "title": "5. How We Use Collected Information",
        "content": "All data will be collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes. We will ensure that the collection, use and disclosure of any personal data that we receive from you pursuant to your use of the Website/Platform will be kept in strict accordance with all applicable laws. Machynworks may collect and use Users personal information for the following purposes:",
        "subsections": [
          {
            "title": "5.1 As part of our service:",
            "bullets": [
              "The IP address from where the User access the system for making the agreement is recorded for addressing any future dispute.",
              "Personal information of the User such as the name, mail id, phone number are collected for creating digital proof of delivery certification.",
              "Location coordinates, altitude, IP address of the system used by the User to access the Website, phone model is recorded for creating digital proof of existence certificate.",
              "The information collected as mentioned above in clause 5.1(a)(i) and clause 5.1(a)(ii) shall only be used for compliant resolution and for performance improvement.",
              "The communication which intended to be processed for certification is to be received from registered clients and stored in our system for forward transmission and delivery certification in line with concept of this solution.",
              "Recipient's concerned data will be used for generating certificate and will be used as a part of mandatory information in order to make a proof of delivery having legal sanctity.",
              "All readable data of the communications made by the User will be deleted from the system in 24 hours before which the User will need to download the certificates if needed.",
              "The generated certificates are stored only in non-readable format which is HASH for future cross verification of issued certificates by User."
            ]
          },
          {
            "title": "5.2 Other purposes:",
            "bullets": [
              "To personalize user experience – We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.",
              "To improve our Website – We may use feedback you provide to improve our products and services.",
              "To send periodic emails – We may use the email address stored in our marketing database to send User information and updates pertaining to their services.",
              "To run a promotion, contest, survey or other Website feature – To send Users information they agreed to receive about topics we think will be of interest to them."
            ]
          }
        ]
      }
    ],
    "contactInformation": {
      "company": "Synerthink SOLUTIONS LLP",
      "email": "vcare@mail.machynworks.com"
    }
  }