import React from 'react';
import styles from '../styles/privacypolicy.module.css';
import PageHeader from '../../common/components/PageHeader';

const BulletList = ({ items }) => (
    <ul className={styles.bulletList}>
      {items.map((item, index) => (
        <li key={index} className={styles.bulletItem}>{item}</li>
      ))}
    </ul>
  );
  
  const Section = ({ title, content, bullets, subsections }) => (
    <div className={styles.sectionContainer}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      {Array.isArray(content) ? (
        content.map((paragraph, index) => (
          <p key={index} className={styles.sectionParagraph}>{paragraph}</p>
        ))
      ) : (
        <p className={styles.sectionParagraph}>{content}</p>
      )}
      {bullets && <BulletList items={bullets} />}
      {subsections && subsections.map((subsection, index) => (
        <div key={index} className={styles.subsectionContainer}>
          <h3 className={styles.subsectionTitle}>{subsection.title}</h3>
          <p className={styles.sectionParagraph}>{subsection.content}</p>
          {subsection.bullets && <BulletList items={subsection.bullets} />}
        </div>
      ))}
    </div>
  );
  
  const PrivacyPolicy = ({ policy }) => {
    return (
        <div className={styles.privacyParentContainer}>
      <div className={styles.privacyContainer}>
        <PageHeader string={policy.title}/>
        {/* <h1 className={styles.privacyTitle}>{policy.title}</h1> */}
        <p className={styles.privacyUpdated}>Last Updated: {policy.lastUpdated}</p>
        
        {policy.sections.map((section, index) => (
          <Section key={index} {...section} />
        ))}
        
        <div className="mt-8">
          <h2 className={styles.contactTitle}>Contact Information</h2>
          <p className={styles.contactDetails}>{policy.contactInformation.company}</p>
          <p className={styles.contactDetails}>Email: {policy.contactInformation.email}</p>
        </div>
      </div>
      </div>
    );
  };
  
  export default PrivacyPolicy;