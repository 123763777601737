import React from "react";
import styles from "../styles/vision.module.css";

function VisionandMission({ data = [] }) {
  return (
    <div className={styles.vision}>
      <div className={styles.innerWrapper}>
        <div className={styles.aboutWrapper}>
          {data.map(({ title, content }, i) => (
            <div key={i} style={{ width: "30%" }}>
              <span className={styles.heading}>{title}</span>
              <p className={styles.paragraphStyle}>{content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VisionandMission;
