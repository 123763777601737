import React from 'react'

function PageHeader({string="Heading"}) {
  return (
    <div style={{
        width:"100%",
        backgroundColor:"#CD9405",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:10,
        // margin:0
    }}>
      <span style={{
        fontSize:24,
        fontWeight:"bold",
        

      }}>{string}</span>
    </div>
  )
}

export default PageHeader
