import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BottomTabNavigation.css";
import profile from "../app/assets/images/profile.png";
import setting from "../app/assets/images/setting.png";
import home from "../app/assets/images/home.png";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar } from "../redux/Actions/UserAction";

const BottomTabNavigation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentRouteName = location.pathname;
	const navbar = useSelector((state) => state.user.navbar);
	const dispatch = useDispatch();
	const handleNavigate = (path) => {
		navigate(path);
	};

	const renderTab = (path, label, icon, flag) => {
		const isActive = currentRouteName === path;
		const filterStyle = !isActive ? "brightness(60%)" : "none";

		return (
			<li
				className={isActive ? "active" : ""}
				onClick={() => {
					flag ? dispatch(setNavbar(!navbar)) : handleNavigate(path);
				}}
			>
				{flag ? (
					<i className="fa fa-ellipsis-v"></i>
				) : (
					<img
						src={icon}
						alt={label}
						className="bottom-icon"
						style={{ filter: filterStyle }}
					/>
				)}
				<p>{label}</p>
			</li>
		);
	};

	return (
		<nav className="bottom-nav">
			<ul className="bottom-ul">
				{renderTab("/", "Home", home)}
				{renderTab("/setting", "Setting", setting)}
				{renderTab("/profile", "Profile", profile)}
				{renderTab(currentRouteName, "More", profile, true)}
			</ul>
		</nav>
	);
};

export default BottomTabNavigation;
