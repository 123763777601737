import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { EmailVerified } from "../../API";
import verifiedEmail from "../../assets/images/verifiedemail.png";
import failedEmail from "../../assets/images/emailfailed.png";

const VerifiedEmail = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});

	const [isLoading, setLoading] = useState(false);
	const [text, setText] = useState(``);

	useEffect(() => {
		const url = window.location.href;
		const pathSegments = url.split("/");
		const id = pathSegments[4];
		const verify_code = pathSegments[5];
		console.log("id:", id);
		console.log("verify_code:", verify_code);
		setLoading(true);
		setTimeout(() => {
			VerifiedEmail(id, verify_code);
		}, 2000);
	}, []);

	const VerifiedEmail = (id, verify_code) => {
		setLoading(true);
		var formdata = new FormData();
		formdata.append("id", id);
		formdata.append("verify_code", verify_code);
		try {
			EmailVerified(formdata).then((response) => {
				console.log(JSON.stringify(response, null, 4));
				if (response?.response === "ok") {
					console.log(response);
					setLoading(false);
					toast.success("Email verified successfully!");
					setText(`Email Verified successfully\nThanks!`);
				} else if (response?.reponse?.includes("Duplicate entry")) {
					setLoading(false);
					setText(`Email already verified!`);
					toast.error("Email already verified!");
				} else {
					setLoading(false);
					setText(`Email verification failed!`);
					toast.error("TempUser matching query does not exist");
					// console.log(response);
					// setLoading(false);
					// toast.success("Email verified successfully!");
					// setText(`Email Verified successfully\nThanks!`);
				}
			});
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Something went wrong!");
		}
	};
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					textAlign: "center",
					height: "100vh",
				}}
			>
				<div className="card">
					{isLoading ? (
						<Loader />
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<img
								src={
									text?.includes("Email Verified successfully") ||
									text?.includes("Email already verified!")
										? verifiedEmail
										: failedEmail
								}
								style={{ width: isMobile ? "220px" : "320px" }}
							/>
							<span
								style={{
									fontSize: isMobile ? "22px" : "32px",
									color:
										text?.includes("Email Verified successfully") ||
										text?.includes("Email already verified!")
											? "#6DD627"
											: "red",
									fontWeight: "600",
								}}
							>
								{text}
							</span>
							<br />
							<Button
								name={
									text?.includes("Email Verified successfully")
										? "Back To Login"
										: "ok"
								}
								path="/"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default VerifiedEmail;
