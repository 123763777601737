import translations from "../../app/screens/translations";
import {
	TOKEN_FUNCTION,
	USERINFO,
	NAV_BAR,
	SettingType,
	PictureType,
	TeriffData,
	TeriffPlan,
	PaymentHistoryAction,
	Language,
	ModalManager,
	AdminInfo,
} from "../ActionTypes";

let initialState = {
	token: null,
	tokenfunc: null,
	navbar: true,
	userInfo: null,
	settingData: null,
	profilePic: null,
	teriffPlan: [],
	teriffData: [],
	paymentHistoryData: [],
	locale: translations.English,
	modalData: null,
	adminInfo:null
};

export const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOKEN_FUNCTION:
			return {
				...state,
				tokenfunc: action.payload,
			};
		case NAV_BAR:
			return {
				...state,
				navbar: action.payload,
			};
		case USERINFO:
			return {
				...state,
				userInfo: action.payload,
			};
		case SettingType:
			return {
				...state,
				settingData: action.payload,
			};
		case PictureType:
			return {
				...state,
				profilePic: action.payload,
			};
		case TeriffData:
			return {
				...state,
				teriffData: action.payload,
			};
		case TeriffPlan:
			return {
				...state,
				teriffPlan: action.payload,
			};
		case PaymentHistoryAction:
			return {
				...state,
				paymentHistoryData: action.payload,
			};
		case Language:
			return {
				...state,
				locale: translations[action.payload || "English"],
			};
		case ModalManager:
			return {
				...state,
				modalData: action.payload,
			};
		case AdminInfo:
			return {
				...state,
				adminInfo: action.payload,
			};
		default:
			return state;
	}
};
