import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import AboutHeader from "./components/AboutHeader";
import AboutConent from "./components/AboutConent";
import VisionandMission from "./components/VisionandMission";
import SMSmarketing from "../../../assets/images/SMS-marketing.jpg";
import useLocale from "../../../../utility/useLocal";

const DashBoardAbout = () => {
  const {
    AboutUS,
    AboutUsContent,
    Vision,
    VisionContent,
    Mission,
    MissionContent,
  } = useLocale();
  return (
    <PageWrapper>
      <AboutHeader heading={AboutUS}/>
      <AboutConent
        heading={AboutUS}
        image={SMSmarketing}
        content={[AboutUsContent]}
      />
      <VisionandMission
        data={[
          {
            title: Vision,
            content: VisionContent,
          },
          {
            title: Mission,
            content: MissionContent,
          },
        ]}
      />
    </PageWrapper>
  );
};

export default DashBoardAbout;
