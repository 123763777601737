import { iconsImgs } from "../../utils/images";
import "./ContentTop.css";
import { useContext } from "react";
import { SidebarContext } from "../../context/sidebarContext";
import { useLocation } from 'react-router-dom';

const ContentTop = () => {
  const { toggleSidebar } = useContext(SidebarContext);
  const location = useLocation();

  const pageTitles = {
    '/': 'Home',
    '/referralCodeTracker': 'Referral Code Tracker',
    '/transaction': 'Transaction Monitoring',
    '/settings': 'Settings',
  };

  const currentTitle = pageTitles[location.pathname] || 'Default Title';

  return (
    <div className="admin-main-content-top">
        <div className="admin-content-top-left">
            <button type="button" className="admin-sidebar-toggler" onClick={() => toggleSidebar() }>
                <img src={ iconsImgs.menu } alt="" />
            </button>
            <h3 className="admin-content-top-title">
                {currentTitle}
            </h3>
        </div>
        <div className="admin-content-top-btns">
            {/* <button type="button" className="search-btn content-top-btn">
                <img src={ iconsImgs.search } alt="" />
            </button> */}
            <button className="admin-notification-btn admin-content-top-btn">
                <img src={ iconsImgs.bell } />
                <span className="admin-notification-btn-dot"></span>
            </button>
        </div>
    </div>
  )
}

export default ContentTop
