import React from "react";
import { useMediaQuery } from "react-responsive";
const Product = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="about_card">
					<div className="about_container">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexWrap: "wrap",
							}}
						>
							<h1>
								<u style={{ color: "#1577b4" }}>Our Products</u>
							</h1>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<img
									alt="img"
									src={require("../../assets/images/product.png")}
									style={{
										width: "auto",
										height: isMobile ? 200 : 240,
										display: "flex",
										alignItems: "flex-end",
										justifyContent: "flex-end",
									}}
								/>
							</div>
						</div>

						<div className="product-us">
							<h2 style={{ color: "#fff", margin: 0 }}>
								A. Certified Communication Service
							</h2>
							<ul style={{ fontSize: 16 }}>
								<li>
									Certificate considered to be legally binding document under
									Indian IT act and Evidence Act
								</li>
								<li>Digitally processed</li>
								<li>Certificate from trusted 3rd Party</li>
								<li>Secured storage for future validation</li>
								<li>Easy to use and to verify its authenticity</li>
								<li>Fast Communication, Cost Effective & Eco Friendly</li>
								<h3>1. Email & SMS Certification</h3>
								<li>
									This product certifies delivery of electronic messages such as
									SMS and E-mail and issues Digitally Signed Certificate
								</li>
								<li>
									Certificate contains details including sender & recipient
									detail, Date & Time of delivery, Content of the message and
									attachments if any along with technical support details
								</li>
							</ul>
							<h2 style={{ color: "#fff", margin: 0 }}>
								B. Verifiable SMS Service
							</h2>
							<ul style={{ fontSize: 16 }}>
								<li>
									SMS sent through our system can be re-verified by the
									recipient
								</li>
								<li>Easy to verify received SMS for its authenticity</li>
								<li>Will avoid spoofing and misleading SMS</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Product;
