import React from "react";
import styles from './styles.module.css'
const Loader = () => {
	return (
		<div className={styles.ldRippleWrapper}>
			<div class={styles.ldRipple}>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Loader;
