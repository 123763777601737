import React from "react";
import { useMediaQuery } from "react-responsive";
import './About.css'
import styles from '../dashboard/modalStyle.module.css'
import { useDispatch } from "react-redux";
import { setModalData } from "../../../redux/Actions/UserAction";
import withBlackBackground from "../dashboard/withBlackBackground";
const Contact = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const dispatch = useDispatch()
	return (
		<div className={styles.modalAnimation} style={{position: 'absolute', maxWidth:isMobile ? '340px' :'540px', height:'340px'}}>
			<span onClick={() => dispatch(setModalData({ type: '', isVisible: false }))} style={{ position: 'absolute', top: -14, right:'10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				marginBottom: 20,
			}}>
				<img src={require("../../assets/images/logo.png")} style={{ width: 60, alignSelf: "center", marginBottom: 0 }} />
				<span style={{ textAlign: 'center' }}>Contact Us</span>
			</div>
					<div className="about_container">
						<div
							className="contact-us"
							style={{
								background: 'rgba(20,97,155,1)',
								backdropFilter: 'blur(4px)'
							}}
						>
							<p>Company: M/s Synerthink Solutions LLP</p>
							<p>Address: Gurugram, Haryana, India.</p>
							<p>Email: support@synerthinksolutions.com</p>
						</div>
					</div>
		</div>
	);
};

export default withBlackBackground(Contact);
