import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadPdf } from "../../../utility";
import withBlackBackground from "../dashboard/withBlackBackground";
import { setModalData } from "../../../redux/Actions/UserAction";
import { useDispatch } from "react-redux";

const CertifiDetails = ({modalData}) => {
	const dispatch = useDispatch()
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const { ATTACH, BODY, FROM, CC, SUBJECT, TO } = modalData?.data || {};
	return (
		<div className="about_card_certificate">
		<span onClick={() => dispatch(setModalData({ type: '', isVisible: false }))} style={{ position: 'absolute', top: -14, right:'10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span>
			<div
				style={{
					border: "2px dotted #14619d",
					height: isMobile ? null : "80vh",
					borderRadius: "12px",
					textAlign: "center",
					padding: "20px",
					boxSizing: "border-box",
					overflow: "scroll",
					background:'#fff'
				}}
			>
				<label
					style={{
						fontSize: isMobile ? "1rem" : "1.8rem",
						letterSpacing: "1px",
						fontWeight: "bold",
						display: "block",
						marginBottom: "10px",
					}}
				>
					Certified Email
				</label>
				<p
					style={{
						fontSize: isMobile ? "0.6rem" : "0.8rem",
						textAlign: "right",
						fontWeight: "600",
						letterSpacing: "1px",
						marginBottom: "10px",
					}}
				>
					From: {FROM}
				</p>
				<p
					style={{
						fontSize: isMobile ? "0.6rem" : "1rem",
						textAlign: "left",
						fontWeight: "500",
						letterSpacing: "1px",
						marginBottom: "10px",
					}}
				>
					<label style={{ fontWeight: "600" }}>Subject:</label> {SUBJECT}
				</p>
				<hr style={{ margin: "10px 0" }} />
				<p
					style={{
						fontSize: isMobile ? "0.6rem" : "1rem",
						textAlign: "left",
						fontWeight: "500",
						letterSpacing: "1px",
						marginBottom: "10px",
					}}
				>
					<label style={{ fontWeight: "600" }}>TO:</label> {TO}
				</p>
				<hr style={{ margin: "10px 0" }} />
				<p
					style={{
						fontSize: isMobile ? "0.6rem" : "1rem",
						textAlign: "left",
						fontWeight: "500",
						letterSpacing: "1px",
						marginBottom: "10px",
						minHeight: isMobile ? 40 : 200,
					}}
				>
					<label style={{ fontWeight: "600" }}>Body:</label>
					<br />
					<br /> {BODY}
				</p>
				<hr style={{ margin: "10px 0" }} />
				<p
					style={{
						fontSize: isMobile ? "0.6rem" : "1rem",
						textAlign: "left",
						fontWeight: "500",
						letterSpacing: "1px",
						marginBottom: "10px",
					}}
				>
					<label style={{ fontWeight: "600" }}>Attachments:</label>
					<br />
					<br />
					{ATTACH?.map((item) => {
						return (
							<label
								style={{ color: "#14619C", cursor: "pointer" }}
								onClick={() => downloadPdf(item?.data, item.title)}
							>
								{item.title}
							</label>
						);
					})}
				</p>
			</div>
		</div>
	);
};

export default withBlackBackground(CertifiDetails);
