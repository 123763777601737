import React from "react";
import styles from "../styles/HeroSectionAbout.module.css";
import emailcontent from "../../../assets/images/emailcontent.webp";
import SMS01 from "../../../assets/images/SMS01.jpg";
import { useSelector } from "react-redux";
import { Redirection_url } from "../../../API/AppUrl";
import { useNavigate } from "react-router-dom";

const HeroSectionAbout = () => {
  const navigate = useNavigate()
  const { locale } = useSelector((state) => state.user)
  return (
    <div className={styles.heroSectionAbout}>
      <div className={styles.innerWrapper}>
        <div className={styles.imageWrapper}>
          <div className={styles.aboutImage}>
            <img
              src={emailcontent}
              className={styles.emailContentImage}
              alt="Email Content"
            />
          </div>
          <div className={styles.smsContentContainer}>
            <img src={SMS01} className={styles.smsImage} alt="SMS Content" />
          </div>
        </div>
        <div className={styles.aboutWrapper}>
          <h1 className={styles.heading}>{locale.AboutCertify}</h1>
          <div>
            <p className={styles.paragraphStyle}>
              {/* Effective communication is vital for businesses and individuals
              alike in today’s fast-paced digital world. With the growing
              reliance on electronic communication channels, ensuring the
              reliability and security of messages is crucial. This is where
              Certifi through Email and SMS &nbsp;comes into play. Certifi is
              provided under the brand name of Certifi by{" "} */}
              {locale.AboutCertifyContent}
         
              <a
                href="https://synerthinksolutions.com/"
                target="_blank"
                className={styles.linkStyle}
              >
                {" "}Synerthink Solutions LLP.
              </a>
              </p>
          </div>
          <div onClick={()=>navigate("/DashBoardAbout")} className={styles.readMoreWrapper}>
            <label className={styles.readMore}>
            {locale.ReadMore}
              <i class="fa fa-chevron-right" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionAbout;
