import React, { useEffect } from 'react'
const withBlackBackground = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);
    return (
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        zIndex: 1000, 
        display: 'flex',          // Use Flexbox
        justifyContent: 'center', // Center horizontally
        alignItems: 'center',
        backdropFilter: 'blur(4px)'
      }}>
        <WrappedComponent {...props} />
      </div>
    );
  };
}

export default withBlackBackground;