export default {
	LOGIN: "login/",
	REGISTER: "register/",
	ALL_USERS:"alluserslist/",
	ACTIVE_USERS:"activeuserslist/",
	DEACTIVE_USERS:"deactivateduserslist/",
	REFERRAL_CODE_TRACKER:'referralcodetrackingreport/',
	ACTIVE_DEACTIVE:'activateordeactivateuser/',
	TRANSACTION_MONITORING:'transactionmonitoring/'
};
