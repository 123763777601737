import React from "react";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AggreementVerify } from "../../API";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import withBlackBackground from "../dashboard/withBlackBackground";
import { setModalData } from "../../../redux/Actions/UserAction";
import styles from '../dashboard/modalStyle.module.css'
const Aggrement = ({ modalData }) => {
	const { locale } = useSelector((state) => state.user);
	const { data, userData } = modalData || {}
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const handleAggre = () => {
		let loader = toast.loading("Loading...");
		var formdata = new FormData();
		formdata.append("email", userData?.email);
		try {
			AggreementVerify(formdata).then((response) => {
				toast.remove(loader);
				toast.success(
					"Account created successfully! please verify your email. "
				);
				dispatch(setModalData({ type: '', isVisible: false }))
			});
		} catch (error) {
			console.log(error);
			toast.error(JSON.stringify(error));
		}
	};

	const handleDecline = ()=>{
		dispatch(setModalData({ type: 'Registration', isVisible: true }))
	}
	return (
		<div className={styles.modalAnimation} style={{ position: 'absolute', maxWidth: '340px', height: '500px' }}>
			{/* <span onClick={() => dispatch(setModalData({ type: 'Registration', isVisible: true }))} style={{ position: 'absolute', top: -14, right: '10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span> */}
			<div style={{ height: 'auto', width: '100%', height: '100%', overflow: 'scroll' }}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: 20,
				}}>
					<img src={require("../../assets/images/logo.png")} style={{ width: 60, alignSelf: "center", marginBottom: 0 }} />
					<span style={{ textAlign: 'center' }}>{locale.AggrementSignature}</span>
				</div>
				<span>{data?.agreement}</span>
				<div class="container" style={{ marginTop: 10 }}>
					<a class="btn btn-1" style={{ borderRadius: 10 }}>
						<div onClick={handleDecline}>
							{locale?.Decline}
						</div>
					</a>
					<a class="btn btn-2" style={{ borderRadius: 10 }}>
						<div
							onClick={handleAggre}
							style={{ textDecoration: "none", color: "#fff" }}
						>
							{locale.Accept}
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default withBlackBackground(Aggrement);
