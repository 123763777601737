// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css";
const DashboardTermsandConditions = () => {
	return (
		<PageWrapper>
			<div className={styles.privacyParentContainer}>
				<div className={styles.privacyContainer}>
					<PageHeader string={"Terms & Conditions"} />
					<h1>Terms and Conditions</h1>
					<ol>
						<li>
							<strong>ACCEPTANCE OF TERMS</strong>
							<ol>
								<li>
									Welcome to{" "}
									<a href="https://certifi.biz">https://certifi.biz</a>{" "}
									(“Website”) or the mobile application by the name of Certifi
									(“Mobile Application”). This website is operated by Synerthink
									Solutions LLP, JMD MEGAPOLIS, 825, Sector 48, Gurugram,
									Haryana 122018 (“We/Us/Our/Company”).
								</li>
								<li>
									These terms and conditions (“Terms of Use”) are an agreement
									between us and you (“User/you/your"). This Agreement sets
									forth the general terms and conditions of your use of the
									Website / Mobile Application and any of its products or
									services (collectively, "Services"). Your access to the
									Website/ Mobile Application and use of the Services is
									conditional on your acceptance of and compliance with these
									Terms of Use as well as the Privacy Policy and Cookie Policy.
									These Terms of Use shall apply to all visitors, users and
									others who access the Website/ Mobile Application or use the
									Services. By accessing or using the Services you agree to be
									bound by these Terms of Use. These Terms of Use shall be read
									along with the terms and conditions under our Privacy Policy
									(please refer to the Privacy Policy section for more
									information).
								</li>
								<li>
									We reserve the right to change these Terms of Use from time to
									time without prior notice. You acknowledge and agree that it
									is your responsibility to review these Terms of Use
									periodically to familiarize yourself with any modifications.
									Your continued use of this Website/ Mobile Application after
									such modifications will constitute acknowledgement and
									agreement of the modified terms and conditions.
								</li>
								<li>
									Use of Website/ Mobile Application is available only to
									persons who can form a legally binding contract under the laws
									of India or any other country as applicable. If you are a
									minor i.e., you may use the Website/ Mobile Application only
									with the involvement of a parent or guardian.
								</li>
							</ol>
						</li>
						<li>
							<strong>TERMS OF MEMBERSHIP WITH THE COMPANY</strong>
							<ol>
								<li>
									The right of admission lies with discretion of the Company.
									You become a member only after your profile is accepted by the
									Company and you make the payments to the Company. The
									membership and rights of admission is reserved solely for 1.
									Indian citizens, 2. Non-Resident Indians, 3. Persons of Indian
									Origin.
								</li>
								<li>
									Length of Membership: You continue to be a member to enjoy the
									services of the Company till the time (whichever is earlier)
									(i) the agreement between the Company and you is in force,
									(ii) account expires or (iii) the membership agreement is
									terminated by you on your own or terminated on account of
									misconduct by the Company on the grounds of violation of Terms
									of Use.
								</li>
								<li>
									A paid membership, rates & period will be decided by the
									Company, from time to time and will be made available on the
									portal as and when needed.
								</li>
								<li>
									The Company is hereby authorised by you to forward the
									received communication by our system on your behalf to
									intended recipients that needs a proof of Delivery
									certificate.
								</li>
								<li>
									The Company is hereby authorised only to Prefix the syntax
									“CERTIFIED” to the subject created by you to identify that the
									communication forwarded for the purpose of certification for
									proof of delivery and to suffix a reference id to facilitate
									recipient to get the communication again at his/her wish to
									eliminate the possibility of fraud phishing/spoofing
									communication. Such resend message shall be Prefixed with
									syntax “RESEND”. Similar prefix & Suffix syntaxes shall be
									added to identify the message correspondingly at any point of
									time.
								</li>
								<li>
									The Company is hereby authorized to prefix / suffix required
									syntax in the SMS text to identify the message to be a
									certified message by the recipient.
								</li>
								<li>
									The company reserves right to implement origination-based
									restriction on SMS certification in compliance with the
									Government of India’s termination policy.
								</li>
								<li>
									Registered customers can use all the certified communication
									services offered by the Company from time to time. The Company
									reserves the right to add any new service or to suspend any
									existing service offered at any point of time without
									assigning any reason.
								</li>
								<li>
									In the event the Company starts a new service and the existing
									customer is desirous of using those services, the existing
									customer hereby accepts that all new terms and conditions
									pertaining to new services is also accepted by the existing
									customer vide this Terms of Use.
								</li>
								<li>
									Right to form Consortium/Associates: While providing services,
									the Company may outsource any part thereof to any competent
									third-party individual or organization, with or without any
									notice/information to its members. However, your membership
									rights and responsibilities continue as normal as against the
									Company only and not against such third-party individual or
									organizations.
								</li>
							</ol>
						</li>
						<li>
							<strong>OTHER TERMS OF ENGAGEMENT</strong>
							<ol>
								<li>
									The Services is for the personal use of individual members
									only and cannot be used for commercial purposes.
								</li>
								<li>
									Upon registration with the Company, you have confirmed that
									you do not have any objection to receive emails, messages and
									calls from the Company as long as you remain a valid member.
								</li>
								<li>
									Multiple additional contact mail ids can be configured by a
									member against his/her profiles of the same person on the
									Website for better service utilisation but primary contact
									mail id will be considered for all purpose against the member.
									The Company reserves the right to deactivate any additional
									multiple mail ids configured without assigning any reason, if
									needed.
								</li>
								<li>
									You should not engage in advertising to, or solicitation of,
									other Members to buy or sell any products or services through
									our Service. You will not transmit any chain letters or junk
									email using our platform. Although the Company is not in a
									position to monitor the conduct of its Members off the Company
									site, it is also a violation of these rules to use any
									information/service obtained through the Company in order to
									harass, abuse, or harm another person, or in order to contact,
									advertise to, solicit, or sell to any Member without their
									prior explicit consent. In order to protect the Company and/or
									our Members from any abuse / misuse, The Company has the
									right, if found reactively, to restrict the communications /
									profile contacts & responses / email which a Member may send
									to others which the Company deems appropriate on its sole
									discretion.
								</li>
								<li>
									The Company has no obligation to review or filter client
									content but has the right to screen communications/messages
									that you may send to others and also regulate the same by
									deleting unwarranted/obscene communications at any time at its
									sole discretion without prior notice to any Member.
								</li>
							</ol>
						</li>
						<li>
							<strong>
								COMMUNICATION RELATIONSHIP BY M/S SYNERTHINK SOLUTIONS LLP WITH
								ITS CUSTOMER
							</strong>
							<ol>
								<li>
									You hereby expressly authorize the Company and/or its
									authorized personnel to communicate to you through any telecom
									resources details in the profile provided by you to explain,
									explicate and clarify the various services provided by the
									Company and to assist aid or support you in availing the said
									services of the Company.
								</li>
								<li>
									If at any time, you wish to discontinue receiving the
									communications (including, but not limited to emails, SMS and
									phone calls) from the Company, you may write an email to{" "}
									<a href="mailto:support@synerthinksolutions.com">
										support@synerthinksolutions.com
									</a>{" "}
									to indicate the displeasure to the Company and/or its
									authorized personnel regarding such discontinuance. You hereby
									agree that, unless expressly communicated to the Company
									and/or its authorized personnel by you about discontinuing
									communications from the Company, it will be deemed to be that
									you want to continue and solicit and invite all such or other
									communications from the Company.
								</li>
								<li>
									As long as you are having any credit balance with the Company
									against your registered id, you will be considered as active
									member irrespective of non-active period. Maximum non-active
									period will be limited to 2 years following which your account
									will be deactivated and balance in the credit will be
									forfeited.
								</li>
								<li>
									Further and in any event, you do hereby unconditionally agree
									and undertake that this authorisation shall supersede any
									preferences set by you with or registration done with the Do
									Not Disturb ("DND Register") / National Customer Preference
									Register ("NCPR"). Without prejudice to the aforesaid and in
									any event, by expressly inviting and soliciting the services
									from the Company, you also unconditionally agree that your
									rights under the Telecom Commercial Communications Customer
									Preference Regulations, 2010 or any subsequent amendments
									thereto or under NCPR, are kept in abeyance or remain
									extinguished till you expressly communicate for
									discontinuation of relationship.
								</li>
								<li>
									You hereby unconditionally agree to indemnify the Company for
									all losses, damages, penalties, costs or consequences whether
									direct or indirect, that may arise out of any breach or
									violation by you of the aforesaid representation, commitment
									and undertaking.
								</li>
								<li>
									When you visit the Website or send e-mails to us, you are
									communicating with us electronically, you consent to receive
									communications from us electronically. We will communicate
									with you by e-mail or by posting notices on this Website. You
									agree that all agreements, notices, disclosures and other
									communications that we provide to you electronically satisfy
									any legal requirement that such communications be in writing.
								</li>
								<li>
									The concept of this service and is put under test and no one
									can fix any responsibility on us the Company or on its
									promoters, shareholders, successors, officers,
									representatives, agents and employees.
								</li>
							</ol>
						</li>
						<li>
							<strong>RESPONSIBLE USE AND CONDUCT</strong>
							<ol>
								<li>
									In order to access our Services, you may be required to
									provide certain information about yourself (such as
									identification, email, phone number, contact details, etc.) as
									part of the registration process, or as part of your ability
									to use the Services. You agree that any information you
									provide will always be accurate, correct, and up to date.
								</li>
								<li>
									You are responsible for maintaining the confidentiality of any
									login information associated with any account you use to
									access our Services. Accordingly, you are responsible for all
									activities that occur under your account/s.
								</li>
								<li>
									Accessing (or attempting to access) any of our Services by any
									means other than through the means we provide, is strictly
									prohibited. You specifically agree not to access (or attempt
									to access) any of our Services through any automated,
									unethical or unconventional means.
								</li>
								<li>
									Engaging in any activity that disrupts or interferes with our
									Services, including the servers and/or networks to which our
									Services are located or connected, is strictly prohibited.
								</li>
								<li>
									Attempting to copy, duplicate, reproduce, sell, trade, or
									resell our resources is strictly prohibited.
								</li>
								<li>
									You are solely responsible for any consequences, losses, or
									damages that we may directly or indirectly incur or suffer due
									to any unauthorized activities conducted by you, as explained
									above, and may incur criminal or civil liability.
								</li>
								<li>
									We may provide various open communication tools on our
									website, such as product ratings and reviews, etc. You
									understand that generally we do not pre-screen or monitor the
									content posted by users of these various communication tools,
									which means that if you choose to use these tools to submit
									any type of content to our Website/ Mobile Application, then
									it is your personal responsibility to use these tools in a
									responsible and ethical manner. By posting information or
									otherwise using any open communication tools as mentioned, you
									agree that you will not upload, post, share or otherwise
									distribute any content:
									<ol>
										<li>
											a) that is illegal, threatening, defamatory, abusive,
											harassing, degrading, intimidating, fraudulent, deceptive,
											invasive, racist, or contains any type of suggestive,
											inappropriate, or explicit language;
										</li>
										<li>
											b) that infringes on any trademark, patent, trade secret,
											copyright, or other proprietary rights of any party;
										</li>
										<li>
											c) that contains any type of unauthorized or unsolicited
											advertising;
										</li>
										<li>
											d) that impersonates any person or entity, including any
											https://main.certifi.biz employees or representatives;
										</li>
										<li>
											e) to harass, abuse, insult, harm, defame, slander,
											disparage, intimidate, or discriminate based on gender,
											sexual orientation, religion, ethnicity, race, age,
											national origin, or disability;
										</li>
										<li>f) to submit false or misleading information;</li>
										<li>
											g) to upload or transmit viruses or any other type of
											malicious code that will or may be used in any way that
											will affect the functionality or operation of the Service
											or of any related website, other websites, or the
											Internet;
										</li>
										<li>
											h) to collect or track the personal information of others;
										</li>
										<li>
											i) to spam, phish, pharm, pretext, spider, crawl, or
											scrape;
										</li>
										<li>j) for any obscene or immoral purpose; or</li>
										<li>
											k) to interfere with or circumvent the security features
											of the Service or the Website/ Mobile Application or any
											related website, other websites, or the internet. We
											reserve the right to terminate your use of the Service or
											any related website for violating any of the prohibited
											uses.
										</li>
									</ol>
								</li>
								<li>
									We have the right at our sole discretion to remove any content
									that, we feel in our judgment does not comply with this Terms
									of Use, along with any content that we feel is otherwise
									offensive, harmful, objectionable, inaccurate, or violates any
									third party copyrights or trademarks. We are not responsible
									for any delay or failure in removing such content. If you post
									content that we choose to remove, you hereby consent to such
									removal, and consent to waive any claim against us.
								</li>
								<li>
									We do not assume any liability for any content posted by you
									or any other third party users of our Website / Mobile
									Application. However, any content posted by you using any open
									communication tools on our website, provided that it doesn't
									violate or infringe on any third party copyrights or
									trademarks, becomes the property of the Company and as such,
									gives us a perpetual, irrevocable, worldwide, royalty-free,
									exclusive license to reproduce, modify, adapt, translate,
									publish, publicly display and/or distribute as we see fit.
									This only refers and applies to content posted via open
									communication tools as described and does not refer to
									information that is provided as part of the registration
									process, necessary in order to use our Services. All
									information provided as part of our registration process is
									covered by our Privacy Policy.
								</li>
								<li>
									You agree to indemnify and hold the Company harmless and its
									parent company and affiliates, and their directors, officers,
									managers, employees, donors, agents, and licensors, from and
									against all losses, expenses, damages and costs, including
									reasonable attorneys' fees, resulting from any violation of
									these Terms of Use or the failure to fulfil any obligations
									relating to your account incurred by you or any other person
									using your account. We reserve the right to take over the
									exclusive defence of any claim for which we are entitled to
									indemnification under these Terms of Use. In such event, you
									shall provide us with such cooperation as is reasonably
									requested by us.
								</li>
								<li>
									Although this Website / Mobile Application may link to other
									websites, we are not, directly or indirectly, implying any
									approval, association, sponsorship, endorsement, or
									affiliation with any linked website, unless specifically
									stated herein. We are not responsible for examining or
									evaluating, and does not warrant the offerings of, any
									businesses or individuals or the content of their websites. We
									do not assume any responsibility or liability for the actions,
									products, services, and content of any other third-parties.
									You should carefully review the legal statements and other
									conditions of use of any website which you access through a
									link from this Website / Mobile Application. Your linking to
									any other off-site websites is at your own risk.
								</li>
							</ol>
						</li>
						<li>
							<strong>PRIVACY</strong>
							<ol>
								<li>
									Your privacy is very important to us, which is why we've
									created a separate Privacy Policy in order to explain in
									detail how we collect, manage, process, secure, and store your
									private information. Our privacy policy is included under the
									scope of these Terms of Use.
								</li>
							</ol>
						</li>
						<li>
							<strong>DATA AND ACCOUNT SECURITY</strong>
							<ol>
								<li>
									In consideration of your use of the Website/ Mobile
									Application, you agree to: (a) maintain and promptly update
									any information you provide to the Company, to keep it
									accurate, current and complete.
								</li>
								<li>
									The User shall be responsible for all usage of the User's
									account and password, whether or not authorized by the User.
								</li>
							</ol>
						</li>

						<li>
							<strong>USERS' CODE OF CONDUCT</strong>
							<ol>
								<li>
									This Website/ Mobile Application is intended for users of the
									Website/ Mobile Application only.
								</li>
								<li>
									You are specifically prohibited from: (i) downloading,
									copying, or retransmitting any or all of the Website/ Mobile
									Application content without, or in violation of, a written
									license or agreement.
								</li>
							</ol>
						</li>
						<li>
							<strong>LIMITATION OF LIABILITY</strong>
							<ol>
								<li>
									You expressly understand and agree that any claim against us
									shall be limited to the amount you paid, if any, for use of
									products and/or services. The Company will not be liable for
									any direct, indirect, incidental, consequential or exemplary
									loss or damages which may be incurred by you as a result of
									using our Services, or as a result of any changes, data loss
									or corruption, cancellation, loss of access, or downtime to
									the full extent that applicable limitation of liability laws
									apply.
								</li>
							</ol>
						</li>
						<li>
							<strong>COPYRIGHTS / TRADEMARKS</strong>
							<ol>
								<li>
									All content and materials available on
									https://main.certifi.bizincluding but not limited to text,
									graphics, website name, code, images and logos are the
									intellectual property of the Company, and are protected by
									applicable copyright and trademark law. Any inappropriate use,
									including but not limited to the reproduction, distribution,
									display or transmission of any content on this Website/ Mobile
									Application is strictly prohibited unless specifically
									authorized by the Company.
								</li>
								<li>
									This Website/ Mobile Application is operated by us. All the
									content published, featured or displayed on the Website/
									Mobile Application, including, but not limited to, text,
									graphics, data, images (photographic and moving),
									illustrations, software and selection and arrangement thereof
									("Content"), is owned by us. Any Content will be published,
									featured or displayed on the Website/ Mobile Application with
									the prior agreement with the Company.
								</li>
								<li>
									You may not use meta-tags or any other "hidden text" utilizing
									content or any other name, trademark or product name of the
									Company without our prior written permission.
								</li>
								<li>
									In addition, the look and feel of the Website/ Mobile
									Application, including all page headers, custom graphics,
									button icons and scripts, trademark and/or trade dress of the
									Company and may not be copied, imitated or used, in whole or
									in part, without the Company’s prior written permission. All
									other trademarks, registered trademarks, product names and
									company names or logos mentioned on the Website/ Mobile
									Application are the property of their respective owners.
									Reference to any products, services, processes or other
									information, by trade name, trade mark, manufacturer, supplier
									or otherwise does not constitute or imply endorsement,
									sponsorship or recommendation thereof by the Company.
								</li>
								<li>
									This Terms of Use does not transfer to you any intellectual
									property owned by the Company or third-parties, and all
									rights, titles, and interests in and to such property will
									remain (as between the parties) solely with the Company. All
									trademarks, graphics and logos used in connection with our
									Website/ Mobile Application or Services, are trademarks or
									registered trademarks of the Company or the Company’s
									licensors. Other trademarks, graphics and logos used in
									connection with the Website/ Mobile Application or Services
									may be the trademarks of other third-parties. Your use of the
									Website/ Mobile Application and Services grants you no right
									or license to reproduce or otherwise use any Company or
									third-party trademarks.
								</li>
							</ol>
						</li>
						<li>
							<strong>TERMINATION OF USE</strong>
							<ol>
								<li>
									You agree that we may, at our sole discretion, suspend or
									terminate your access to all or part of our Website/ Mobile
									Application and resources with or without notice and for any
									reason, including, without limitation, breach of this Terms of
									Use. Any suspected illegal, fraudulent or abusive activity may
									be grounds for terminating your relationship and may be
									referred to appropriate law enforcement authorities. Upon
									suspension or termination, your right to use the Services
									provided by us will immediately cease, and we reserve the
									right to remove or delete any information that you may have on
									file with us, including any account or login information.
								</li>
							</ol>
						</li>

						<li>
							<strong> GOVERNING LAW</strong>
							<ol>
								<li>
									The Website/ Mobile Application is controlled by the Company
									from our offices located in India. It can be accessed by most
									countries around the world. As each country has laws that may
									differ from those of India, by accessing our Website/ Mobile
									Application, you agree that the statutes and laws of India
									without regard to the conflict of laws will apply to all
									matters relating to the use of this Website/ Mobile
									Application and the purchase of any products or services
									through this Website/ Mobile Application.
								</li>
								<li>
									Any issues arising out of use of Website/ Mobile Application
									and any conflict with regard to these Terms of Use and Privacy
									Policy shall be governed and construed in accordance with the
									laws of India and subject to exclusive jurisdiction of courts
									in Chennai, Tamilnadu. You hereby agree to personal
									jurisdiction by such courts and waive any jurisdictional,
									venue, or inconvenient forum objections to such courts. The
									Company’s failure to enforce any right or provision of these
									Terms of Use will not be considered a waiver of those rights.
								</li>
							</ol>
						</li>

						<li>
							<strong>CANCELLATION AND REFUND</strong>
							<ol>
								<li>
									If you do not verify your mail id within specified duration at
									the time of registration, your registration will be aborted,
									and amount already paid by you will be forfeited by the
									Company.
								</li>
								<li>
									If you opt to terminate your membership with the Company, the
									balance credit against your customer id will not be refunded
									under any circumstance and will be forfeited by the Company.
								</li>
								<li>
									As mentioned early above, as long as you are having any credit
									balance with the Company against your registered id, you will
									be considered as active member irrespective of non-active
									period. Maximum non-active period will be limited to 2 years
									following which your account will be deactivated and balance
									in the credit will be forfeited by the Company.
								</li>
							</ol>
						</li>

						<li>
							<strong>GUARANTEE</strong>
							<ol>
								<li>
									Unless otherwise expressed, the Company expressly disclaims
									all warranties and conditions of any kind, whether express or
									implied, including, but not limited to the implied warranties
									and conditions of merchantability, fitness for a particular
									purpose and non-infringement.
								</li>
							</ol>
						</li>

						<li>
							<strong>DISCLAIMERS</strong>
							<ol>
								<li>
									The Company does not make any representation or warranty about
									the Website/ Mobile Application or the services and products,
									except as expressly stated in the Terms of Use. Nothing on the
									Website/ Mobile Application is to be deemed as authoritative
									advice (whether legal, medical, financial, etc.) of any kind.
								</li>
								<li>
									You must bear the risks associated with the use of the
									Website/ Mobile Application. We will not be responsible for
									any errors or omissions or for the results obtained from the
									use of such information or for any technical problems you may
									experience with the Website/ Mobile Application.
								</li>
								<li>
									This disclaimer constitutes an essential part of these Terms
									of Use. To the fullest extent permitted under applicable law,
									we shall not be liable for any indirect, incidental, special,
									consequential or exemplary damages, including but not limited
									to, damages for loss of profits, goodwill, use, data or other
									intangible losses arising out of or in connection with the
									Website/ Mobile Application, its services or these Terms of
									Use.
								</li>
							</ol>
						</li>

						<li>
							<strong>PAYMENTS</strong>
							<ol>
								<li>
									Payment can be made in Rupees by one of the following: Net
									Banking, UPI, Wallets, or such other payment mode as may be
									specified by us from time to time. Additional terms and
									conditions may apply depending upon your payment mode. By
									making payment through such mode, you agree to the terms and
									conditions that apply to such mode of payment. We reserve the
									right to add or remove or modify the terms and conditions for
									such payment options without prior notice.
								</li>
								<li>
									While availing any of the payment methods available on our
									Website/ Mobile Application, we will not be responsible or
									assume any liability, whatsoever in respect of any loss or
									damage arising directly or indirectly to You due to:
									<ol type="a">
										<li>
											Exceeding the pre-set limit mutually agreed by you and
											your bank
										</li>
										<li>Lack of authorization for any transaction/s, or</li>
										<li>
											Any payment issues arising out of the transaction, or
										</li>
										<li>Decline of transaction for any other reason/s</li>
										<ol type="1">
											<li>
												All payment transactions are subject to approval by your
												issuing bank / financial institution as applicable. If
												your bank / financial institution refuses to authorize
												payment, we reserve the right to cancel the order
												without any liability. Further, you agree that it will
												not hold us accountable for any delays caused to the
												delivery in cases where the payment authorization is
												delayed by your bank/ financial institution.
											</li>
											<li>
												We reserve the right to decline transaction by customer
												with a history of questionable charges including but not
												limited to breach of any agreement by the customer with
												us or breach/violation of any law or any charges imposed
												by issuing bank or breach of any policy.
											</li>
										</ol>
									</ol>
								</li>
							</ol>
						</li>

						<li>
							<strong>ENTIRE TERMS</strong>
							<ol>
								<li>
									If any part of these Terms of Use is determined to be invalid
									or unenforceable pursuant to applicable law including, but not
									limited to, the warranty disclaimers and liability limitations
									set forth above, then the invalid or unenforceable provision
									will be deemed to be superseded by a valid, enforceable
									provision that most closely matches the intent of the original
									provision and the remainder of the Terms shall continue in
									effect. Unless otherwise specified herein, these Terms and the
									Website/ Mobile Application policies constitute the entire
									agreement between you and us with respect to our
									sites/services and it supersedes all prior or contemporaneous
									communications and proposals, whether electronic, oral or
									written, between you and us with respect to the Website/
									Mobile Application. Our failure to act with respect to a
									breach by you or others does not waive our right to act with
									respect to subsequent or similar breaches.
								</li>
							</ol>
						</li>

						<li>
							<strong>FORCE MAJEURE</strong>
							<ol>
								<li>
									We will not be liable or responsible for any failure to
									perform, or delay in performance of, any of our obligations
									under a contract that is caused by an “Event Outside Our
									Control”. An Event Outside Our Control is defined below. An
									Event Outside Our Control means any act or event beyond our
									reasonable control, including without limitation strikes,
									lock-outs or other industrial action by third parties, civil
									commotion, riot, invasion, terrorist attack or threat of
									terrorist attack, war (whether declared or not) or threat or
									preparation for war, fire, explosion, storm, flood,
									earthquake, subsidence, epidemic, pandemic, national or local
									lockdown, or other natural disaster, or failure of public or
									private telecommunications networks, or impossibility of the
									use of shipping, aircraft, motor transport or other means of
									public or private transport.
								</li>
								<li>
									If an Event Outside Our Control takes place that affects the
									performance of our obligations under a contract: We will
									contact you as soon as reasonably possible to notify you and
									our obligations under the contract will be suspended and the
									time for performance of our obligations will be extended for
									the duration of the Event Outside Our Control. Where the Event
									Outside Our Control affects our delivery of products to you,
									we will arrange a new delivery date with you after the Event
									Outside Our Control is over.
								</li>
							</ol>
						</li>

						<li>
							<strong>CONTACT INFORMATION</strong>
							<p>
								If you have any questions or comments about our Terms of Service
								as outlined above, you can contact us at:
							</p>
							<p>
								Synerthink Solutions LLP
								<br />
								Email:{" "}
								<a href="mailto:support@synerthinksolutions.com">
									support@synerthinksolutions.com
								</a>
							</p>
						</li>
					</ol>
				</div>
			</div>
		</PageWrapper>
	);
};

export default DashboardTermsandConditions;
