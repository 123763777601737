import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, CircularProgress } from '@mui/material';
import ResponsiveTable from '../../../components/ResponsiveTable';
import apiCall from '../../../API/apiService';
import apiEndpoints from '../../../API/apiEndpoints';
import toast from 'react-hot-toast';
import Loader from '../../../components/Loader';

const Dashboard = () => {
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({
        allUsers: [],
        activeUsers: [],
        inactiveUsers: []
    });
    const [filteredUsers, setFilteredUsers] = useState(userData.allUsers);

    useEffect(() => {
        fetchUsersData();
    }, []);

    const fetchUsersData = async () => {
        setLoading(true);
        const fetchData = async (endpoint) => {
            const result = await apiCall("POST", endpoint, {});
            return result?.response || [];
        };

        const [allUsers, activeUsers, inactiveUsers] = await Promise.all([
            fetchData(apiEndpoints.ALL_USERS),
            fetchData(apiEndpoints.ACTIVE_USERS),
            fetchData(apiEndpoints.DEACTIVE_USERS),
        ]);

        setUserData({ allUsers, activeUsers, inactiveUsers });
        setLoading(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const reFetchData = () => {
        fetchUsersData()
    };

    const tabs = [
        { label: 'All Users', data: userData.allUsers },
        { label: 'Active Users', data: userData.activeUsers },
        { label: 'Inactive Users', data: userData.inactiveUsers },
    ];

    const getTabStyle = (index) => ({
        color: value === index ? '#fff' : '#bdbabb',
        '&.Mui-selected': { color: '#fff', backgroundColor: '#fe6c00' },
        '&.Mui-disabled': { color: '#fe6c00' },
    });

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                sx={{
                    border: '2px solid #fe6c00',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#fe6c00',
                        border: 'none'
                    },
                    borderRadius: '8px',
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} sx={getTabStyle(index)} />
                ))}
            </Tabs>
            <Box sx={{ py: 3 }}>
                {loading ? (
                    <Loader />
                ) : (
                    <ResponsiveTable
                        key={value}
                        initialRows={tabs[value].data}
                        data={{ isAction: value == 0 ? false : true, actionType: value == 1 ? "Active" : "Deactive" }}
                        reFetchData={reFetchData}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Dashboard;
