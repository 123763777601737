import './AdminApp.css';
import Sidebar from './layout/Sidebar/Sidebar';
import Content from './layout/Content/Content';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './layout/Page/Login';
import { Toaster } from 'react-hot-toast';

function AdminApp() {
  const MainRoute = () => {
    return (
      <>
        <Sidebar />
        <Content />
      </>
    )
  }

  const AuthRoute = () => {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
    )
  }

  return (
    <>
      <div className='admin-app'>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <MainRoute />
      </div>
    </>
  )
}

export default AdminApp
