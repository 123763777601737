import React, { useState } from "react";
import "./modal.css";
// Custom modal hook
const usePopupModal = () => {
	const [modalVisible, setModalVisible] = useState(false);

	const showModal = () => {
		setModalVisible(true);
	};

	const hideModal = () => {
		setModalVisible(false);
	};

	const ModalComponent = ({ children }) => {
		return modalVisible ? (
			<div className="modal-wrapper">
				{children}
			</div>
		) : null;
	};

	return { showModal, hideModal, ModalComponent, modalVisible };
};

export default usePopupModal;
