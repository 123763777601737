import React from 'react'
import styles from '../styles/aboutHeader.module.css'
function AboutHeader({heading="About Us"}) {
  
  return (
    <div className={styles.aboutHeader} data-aos="fade-in">
        <div className={styles.headingWrapper}>
            <span  className={styles.heading}>{heading}</span>
        </div>
    </div>
  )
}

export default AboutHeader
