import { configureStore } from "@reduxjs/toolkit";
import rootReducer from '../redux/Reducers'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import local storage

const persistConfig = {
	key: "root", // A unique key
	storage, // Use local storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
});

const persistor = persistStore(store);
const clearPersistedState = () => {
    persistor.purge();
  };
  

export { store, persistor,clearPersistedState };
