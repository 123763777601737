import React from "react";
import styles from "../styles/Footer.module.css";
import logo from "../../../assets/images/hrlogo.png";
import facebook from "../../../assets/images/facebook.png";
import linkedin from "../../../assets/images/linkedin.png";
import twitter from "../../../assets/images/twitter.png";
import youtube from "../../../assets/images/instagram.png";
import useLocale from "../../../../utility/useLocal";
import { useNavigate } from "react-router-dom";
const socialMediaLinks = [
  {
    href: "https://www.facebook.com/certificcs",
    src: facebook,
    alt: "Facebook Logo",
  },
  {
    href: "https://www.twitter.com/certificcs",
    src: twitter,
    alt: "Twitter Logo",
  },
  {
    href: "https://www.instagram.com/certifi.ccs",
    src: youtube,
    alt: "Instagram Logo",
  },
  {
    href: "https://www.linkedin.com/company/certificcs",
    src: linkedin,
    alt: "LinkedIn Logo",
  },
];
const quickLinks = [
  "Home",
  "About",
  "Services",
  "Privacy Policy",
  "Terms & Conditions",
];
const Footer = () => {
  const navigate = useNavigate()
  const {
    Footer_Logo_Content,
    Company,
    Address,
    QuickLinks,
    About,
    Home,
    Services,
    PrivacyPolicy,
    Terms_Conditions,
    ContactUs,
  } = useLocale();
  const quickLinks = [
    {lable:Home, link:'/'},
    {lable: About, link:'/DashBoardAbout'},
    {lable:Services, link:'/DashBoardServices'},
    {lable:PrivacyPolicy, link:'/privacy-policy'},
    {lable: Terms_Conditions, link:'/dashboardTermsandConditions'},
  ];
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.column}>
          <img onClick={()=>{navigate('/'); window.scroll(0,0)}} src={logo} alt="Logo" className={styles.logoImage} style={{cursor:'pointer'}} />
          <p className={styles.logoText}>{Footer_Logo_Content}</p>
        </div>
        <div className={styles.column}>
          <h3> {Company}</h3>
          <p style={{cursor: "default", color:'#fff'}} className={`${styles.logoText} ${styles.links}`}>{Address}</p>
          <p style={{cursor: "default", color:'#fff'}}
            className={`${styles.logoText} ${styles.links} ${styles.emailText}`}
          >
            info@synerthinksolutions.com
          </p>
        </div>
        {
          <div className={styles.column}>
            <h3>{QuickLinks}</h3>
            <ul>
              {quickLinks.map((item, index) => (
                <li onClick={()=>navigate(item.link)} key={index} className={styles.links}>
                  {item.lable}
                </li>
              ))}
            </ul>
          </div>
        }
        {
          <div className={styles.column}>
            <h3>{ContactUs}</h3>
            <div className={styles.socialIconsWrapper}>
              {socialMediaLinks.map((social, index) => (
                <div key={index} className={styles.socialIconsContainer}>
                  <a
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={social.src}
                      alt={social.alt}
                      className={`${styles.socialIcons} ${styles.links}`}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        }
      </footer>
      <div className={styles.line} />
      <div className={styles.footerBottomTextContainer}>
        <span className={styles.footerBottomText}>All rights reserved.</span>
      </div>
    </>
  );
};

export default Footer;
