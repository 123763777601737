import React from 'react';
import { useSelector } from 'react-redux';
import AdminApp from './app/screens/admin/App';
import { SidebarProvider } from './app/screens/admin/context/sidebarContext';
import Main from './Main';

const MainBridge = () => {
  const { adminInfo } = useSelector((state) => state.user);

  // Conditional CSS styles
  const globalStyles = adminInfo?.isLogin ? `
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
  ` : '';

  return (
    <>
      <style>{globalStyles}</style>
      {adminInfo?.isLogin ? (
        <SidebarProvider>
          <AdminApp />
        </SidebarProvider>
      ) : (
        <Main />
      )}
    </>
  );
};

export default MainBridge;
