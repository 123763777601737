import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Input.css";
import unlock from "../app/assets/images/unlock.png";
import lock from "../app/assets/images/lock.png";

const Input = ({
	icon,
	placeholder,
	onChangeText,
	key_name,
	value,
	defaultValue,
	disable,
	isWhiteList,
	item,
	onClick,
	isSetting,
	maxLength,
	type,
	fontWeight,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});

	const handleClick = (item) => {
		if (item?.verified !== true && isWhiteList) {
			onClick(item?.email, item?.first_name, item?.last_name);
		} else if (isSetting && item?.mob_verify !== true) {
			onClick();
		}
	};

	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};
	return (
		<div className="input-container">
			{type == "password" ? (
				<img
					onClick={toggleVisibility}
					src={type === "password" && isVisible ? unlock : lock}
					alt="profile"
					className="input-icon"
					style={{ cursor: "pointer" }}
				/>
			) : (
				<img
					onClick={toggleVisibility}
					src={type === "password" && isVisible ? unlock : icon}
					alt="profile"
					className="input-icon"
				/>
			)}

			{isWhiteList || isSetting ? (
				<div
					className="text-input"
					style={{
						padding: 0,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						paddingRight: 10,
					}}
				>
					<input
						value={value || ""}
						placeholder={placeholder}
						className="text-input"
						onChange={(e) => onChangeText(e.target.value, key_name)}
						disabled={disable}
						style={{ padding: 10 }}
					/>
					<label
						onClick={() => handleClick(item)}
						style={{
							backgroundColor: "#fff",
							border: 1,
							borderStyle: "dotted",
							borderColor: "gray",
							padding: 4,
							borderRadius: 4,
							color: item?.verified || item?.mob_verify ? "Green" : "red",
							cursor: item?.verified || item?.mob_verify ? null : "pointer",
							fontSize: isMobile ? 11 : 14,
						}}
					>
						{item?.verified || item?.mob_verify ? "VERIFIED" : "UNVERIFY"}
					</label>
				</div>
			) : (
				<input
					value={value || null}
					defaultValue={defaultValue}
					placeholder={placeholder}
					className="text-input"
					style={{ fontWeight: fontWeight || "normal" }}
					onChange={(e) => onChangeText(e.target.value, key_name)}
					disabled={disable}
					maxLength={maxLength}
					autoComplete="new-password"
					type={isVisible ? "text" : type || "text"}
				/>
			)}
		</div>
	);
};

export default Input;
