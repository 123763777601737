// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css"

const RefundPolicy = () => {
    return (
        <PageWrapper>
            <div className={styles.privacyParentContainer}>
                <div className={styles.privacyContainer}>
                    <PageHeader string={"Refund Policy"} />
                    <p>
                        The Refund Policy for seeking any refund for the CERTIFI Communication Service provided by Synerthink Solutions LLP (SSL), under the brand name of CERTIFI, is as stated below:
                    </p>
                    <p>
                        Please note that One CERTIFI Email and One CERTIFI SMS sent together under the brand name of CERTIFI would be considered One Transaction and charged together.
                    </p>
                    <ol>
                        <li>
                            <strong>FOR PREPAID SERVICE:</strong>
                            <ol>
                                <li>
                                    A refund will be processed if the CERTIFI Email could not be sent for any reasons attributable to SSL. This refund will be processed after due investigation for the failure in 5 working days from the day the CERTIFI Email was sent.
                                </li>
                                <li>
                                    No refund will be processed in case the customer provides incorrect mobile number or Email address.
                                </li>
                                <li>
                                    No refund will be applicable for services rendered by SSL in case a certificate has been issued for a CERTIFI SMS and/or CERTIFI Email sent through CERTIFI Communication Service.
                                </li>
                                <li>
                                    No refund would be processed in case the CERTIFI SMS is not delivered as it is an ancillary service to support CERTIFI Email and sent for informational purposes only.
                                </li>
                                <li>
                                    No refund will be issued in case the customer misuses the system for sending promotion emails. The sender would be liable to pay a fine or any penal penalty/ punishment as decided by the Competent Authority for violation of any law, rule, directions and any instructions etc. of the Competent Authority for misuse of the CERTIFI Communication Service.

                                </li>
                                <li>
                                    The Customer hereby agrees to indemnify and keep indemnified and hold harmless SSL from and against any and all losses, penalties, judgments, suits, costs, claims, liabilities, assessments, damages and expenses (including, without limitation, reasonable attorneys’ fees and disbursements), incurred by, imposed upon or asserted against SSL as a result of relating to or arising out of the breach of the Laws of India and/ or directions, instructions, rules etc. as notified/ gazetted by any Competent Authority as applicable.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </PageWrapper>
    );
};

export default RefundPolicy;