// src/components/ResponsiveTable.js
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import apiEndpoints from '../../API/apiEndpoints';
import apiCall from '../../API/apiService';

const statusStyles = {
    Pending: { color: 'orange' },
    Approved: { color: 'green' },
};

const ResponsiveTable = ({ initialRows, data, reFetchData }) => {
    const [rows, setRows] = useState(initialRows);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleActiveDeactive = async () => {
        if (!currentRow) return;

        const { id, email } = currentRow;
        console.log(currentRow);
        

        const formData = new FormData();
        formData.append("user_id", id);
        formData.append("email", email);
        formData.append("operation", data?.actionType === "Active" ? "deactivate" : "activate");

        try {
            const res = await apiCall("post", apiEndpoints.ACTIVE_DEACTIVE, formData);
            if (res) {
                reFetchData();
            }
        } catch (error) {
            console.log(error, "❌ login/register error");
        }
        console.log('Edit:', currentRow);
        handleClose();
    };

    const handleStatusChange = (id, newStatus) => {
        setRows(rows.map(row => (row.id === id ? { ...row, status: newStatus } : row)));
    };

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#fff', }}>
                            <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem', }}>Username</TableCell>
                            <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem', }}>Email</TableCell>
                            <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem', }}>First Name</TableCell>
                            <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem', }}>Last Name</TableCell>
                            {/* Uncomment these cells if you want to add Status and Actions columns */}

                            {/* <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem',  }}>Status</TableCell> */}
                            {data?.isAction && <TableCell sx={{ color: '#fe6c00', fontWeight: 'bold', fontSize: '1rem', }}>Actions</TableCell>}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.username}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.last_name}</TableCell>

                                {/* <TableCell>
                                    <FormControl variant="outlined" style={{ height: '30px', width: '100px' }}>
                                        <Select
                                            value={row.status}
                                            onChange={(e) => handleStatusChange(row.id, e.target.value)}
                                            style={{
                                                ...statusStyles[row.status],
                                                height: '30px',
                                                width: '120px',
                                                fontSize: '14px',  // Optional, adjust to fit the smaller size
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '0 8px', // Adjust padding to fit within 20px height
                                                },
                                            }}
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                        </Select>
                                    </FormControl>

                                </TableCell> */}
                                {data?.isAction && <TableCell>
                                    <IconButton onClick={(event) => handleClick(event, row)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleActiveDeactive}>
                                        {data?.actionType === "Active" ? "Deactivate" : "Active"}
                                    </MenuItem>
                                </Menu>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[6, 15, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default ResponsiveTable;