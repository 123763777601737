import React from "react";
import styles from "../styles/aboutContent.module.css";

function AboutConent({ heading, image, content ,style}) {
  return (
    <div className={styles.aboutContent} style={{...style}}>
      <div className={styles.innerWrapper}>
        <div className={styles.aboutWrapper}>
          <div className={styles.contentWrapper}>
          <span className={styles.heading}>{heading}</span>
            {content.map((e, i) => (
              <p className={styles.paragraphStyle}>{e}</p>
            ))}
          </div>
        </div>
        <div className={styles.aboutImage}>
          <img
            src={image}
            className={styles.emailContentImage}
            alt="Email Content"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutConent;
