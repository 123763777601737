import axios from "axios";
import { toasty } from "../utils/helper";

// Create an Axios instance
const axiosInstance = axios.create({
	baseURL: "https://test-adminapi.certifi.biz/",
	timeout: 25000,
	headers: {
		"Content-Type": "application/json",
	},
});

// Request Interceptor
axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `Token ${token}`;
		}
		// If token doesn't exist, API call will proceed without it.
		return config;
	},
	(error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		// Handle 401 (Unauthorized) for token refresh
		if (
			error.response &&
			error.response.status === 401 &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			try {
				const refreshToken = localStorage.getItem("refreshToken");

				if (!refreshToken) {
					// If no refresh token, reject the request
					toasty("Session expired, please log in again.");
					return Promise.reject(error);
				}

				const { data } = await axios.post(
					"http://localhost:8000/auth/refresh",
					{ token: refreshToken }
				);

				localStorage.setItem("accessToken", data.accessToken);
				originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
				return axiosInstance(originalRequest);
			} catch (refreshError) {
				console.error("Refresh token failed: ", refreshError);
				toasty("Error refreshing session, please log in again.");
				return Promise.reject(refreshError);
			}
		}

		// Handle other error status codes and display toasty message
		if (error?.response) {
			const status = error?.response?.status;
			const requestUrl = originalRequest.url;
			const errorMessage = error.response.data?.message || "An error occurred";

			switch (status) {
				case 403:
					toasty("You don't have permission to access this resource.", "error");
					break;
				case 400:
					toasty(errorMessage, "error");
					break;
				case 404:
					if (requestUrl.includes('/applicants') || requestUrl.includes('/job-openings')) {
						toasty("No applicants found for this job opening.", "info");
					} else {
						toasty(errorMessage, "error");
					}
					break;
				case 500:
					toasty("Server error, please try again later.", "error");
					break;
				default:
					toasty(errorMessage, "error");
			}
		} else {
			// Handle network errors or timeouts
			toasty("Network error, please check your connection.", "error");
		}

		return Promise.reject(error);
	}
);

export default axiosInstance;
