import React, { useState } from "react";

const DateRangeFilter = () => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	// Function to handle download logic
	const handleDownload = () => {
		if (!fromDate || !toDate) {
			alert("Please select both From Date and To Date.");
			return;
		}

		if (new Date(fromDate) > new Date(toDate)) {
			alert("From Date should not be later than To Date.");
			return;
		}

		// Logic to download data based on the selected date range
		alert(`Downloading data from ${fromDate} to ${toDate}`);
	};

	// Determine if 'To Date' should be disabled
	const isToDateDisabled = fromDate && new Date(fromDate) < new Date();

	return (
		<div style={styles.container}>
			<div style={styles.inputGroup}>
				<label style={styles.label}>From Date:</label>
				<input
					type="date"
					value={fromDate}
					onChange={(e) => setFromDate(e.target.value)}
					style={styles.input}
				/>
			</div>

			<div style={styles.inputGroup}>
				<label style={styles.label}>To Date:</label>
				<input
					type="date"
					value={toDate}
					onChange={(e) => setToDate(e.target.value)}
					style={styles.input}
					min={fromDate}
				/>
			</div>

			<button onClick={handleDownload} style={styles.button}>
				Download
			</button>
		</div>
	);
};

const styles = {
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputGroup: {
		display: "flex",
		alignItems: "center",
		marginRight: "20px",
	},
	label: {
		marginRight: "10px",
		fontWeight: "bold",
	},
	input: {
		padding: "5px",
		borderRadius: "4px",
		border: "1px solid #ccc",
	},
	button: {
		padding: "8px 16px",
		backgroundColor: "#007bff",
		color: "#fff",
		border: "none",
		borderRadius: "4px",
		cursor: "pointer",
	},
};

export default DateRangeFilter;
