import styles from "./Content.module.css";
import ContentTop from '../../components/ContentTop/ContentTop';
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Page/Dashboard";
import ReferralCodeTracker from "../Page/ReferralCodeTracker";
import Transaction from "../Page/Transaction";

const Content = () => {
  return (
    <div className={styles.mainContent}>
      <ContentTop />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/referralCodeTracker" element={<ReferralCodeTracker/>}/>
        <Route path="/transaction" element={<Transaction/>}/>
      </Routes>
    </div>
  );
}

export default Content;
