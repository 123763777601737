import React, { useCallback, useEffect, useState } from "react";
import "../mainScreens/PageContainer.css";
import "../mainScreens/TeriffPlans.css";
import cashfree from "../../../util";
import { v4 as uuidv4 } from "uuid";
import { AggreementVerify } from "../../API";
import Loader from "../../../components/Loader";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
const RegistrationTarrifPlan = () => {
	const [teriffPlan, setTeriffPlan] = useState([]);
	const [teriffData, setTeriffData] = useState([]);
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState(false);
	const { state } = useLocation();
	const { userData } = state || {};
	useEffect(() => {
		GetPlan();
		const url = window.location.href;
		const orderId = url.split("orderId=")[1]?.split("&email")[0];
		if (orderId) {
			getPaymentDetails(orderId);
		}
	}, []);

	const GetPlan = useCallback(async () => {
		const url = window.location.href;
		const email = url.split("email=")[1];
		setLoading(true);
		const formData = new FormData();
		formData.append("email", userData?.email || email);
		try {
			AggreementVerify(formData).then((response) => {
				console.log(JSON.stringify(response, null, 4));
				if (response?.response) {
					const outputArray = Object.entries(
						response?.data?.service_charge
					).map(([name, value], index) => ({
						id: index + 1,
						name,
						value,
					}));
					const res = outputArray?.map((item, index) => {
						return {
							id: item.id,
							name: item.name,
							value: item.value,
						};
					});
					setTeriffPlan(res);
					setTeriffData(response.data?.plans);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, []);

	const handleRedirect = (session_id) => {
		let paymentOptions = {
			paymentSessionId: session_id,
		};
		cashfree.checkout(paymentOptions).then((result) => {
			console.log(result);
		});
	};
	const getPaymentDetails = (orderId) => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({ orderid: orderId }),
		};
		fetch(`https://apisyn.certifi.biz/getpaymentdata/`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				const { order_status, order_note, created_at, customer_details } =
					result?.order_info || {};
				console.log(result, "result===> PaymentData");
				handlePayments(result?.["payment-info"][0], {
					...customer_details,
					order_note: order_note,
					created_at: created_at,
				});
				if (order_status === "PAID") {
					toast.success("Payment successfully done.", {
						duration: 10000,
					});
				} else if (order_status === "FAILED") {
					toast.error("Payment failed", {
						duration: 10000,
					});
				} else if (order_status === "USER_DROPPED") {
					toast.error("Payment cancelled", {
						duration: 10000,
					});
				} else if (order_status === "PENDING") {
					toast.error("Payment pending", {
						duration: 10000,
					});
				}
			})
			.catch((error) => console.log("error==> PaymentData", error));
	};

	const handlePayments = async (data, customer_details) => {
		try {
			let paymentOption = {
				transc_id: data?.order_id,
				ref_id: data?.cf_payment_id,
				mode: data?.payment_group,
				status: data?.payment_status,
				amount: data?.order_amount,
				init_time: customer_details?.created_at,
				product_info: customer_details?.order_note,
				firstname: customer_details?.customer_name,
				email: customer_details?.customer_email,
				phone: customer_details?.customer_phone,
				bank_ref_id: data?.bank_reference,
				paid_amount: data?.payment_amount,
				payment_completion_time: data?.payment_completion_time,
				payment_currency: "INR",
				request_type: 1,
				payment_method: data?.payment_group,
				error_details: "error_details",
			};
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(paymentOption),
			};
			const result = await fetch(
				"https://apisyn.certifi.biz/verifyandagreement/",
				requestOptions
			);
			console.log(result, "result=> handlePayments function");
			if (result.ok) {
				toast.success("Account created successfully.", {
					duration: 10000,
				});
				navigate("/");
			} else {
				toast.error(
					"There are some internal issues. Please connect with the internal team",
					{
						duration: 10000,
					}
				);
			}
		} catch (error) {
			toast.error(
				"There are some internal issues. Please connect with the internal team",
				{
					duration: 10000,
				}
			);
		}
	};

	const generateOrderid = async (amnt, plan) => {
		const uniqueId = uuidv4();
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		const raw = JSON.stringify({
			orderid: uniqueId?.toString(),
			currency: "INR",
			ordernote: plan,
			fname: userData?.first_name,
			mailid: userData?.email,
			phone: userData?.contactNumber,
			cost: amnt,
			return_url: `https://main.certifi.biz/registerplans?orderId=${uniqueId}&email=${userData?.email}`,
			notify_url: "https://main.certifi.biz/notify/",
			req_type: "1",
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		const resp = await fetch(
			"https://apisyn.certifi.biz/beforePayment/",
			requestOptions
		);
		const result = await resp.text();
		handleRedirect(JSON.parse(result).id);
	};

	const ItemContent = ({ item, index }) => {
		if (item.name == "chitrdfr") {
			return;
		}
		return (
			<div key={index} className="teriff-plan-value">
				<span>
					{item.name == "maildfr"
						? "mail"
						: item.name == "smsdfr"
						? "sms"
						: item.name == "reverificationdfr"
						? "reverification"
						: item.name == "form65bdfr"
						? "form65B"
						: item.name}
				</span>
				<span>{item.value}</span>
			</div>
		);
	};
	const PlanCard = ({ item, index }) => {
		return (
			<div key={index} className="teriff-card-wrapper">
				<div
					className={`teriff-card-up`}
					style={{ backgroundColor: item?.plan_color_secondary }}
				>
					<div className="teriff-card-space">
						<span className="teriff-title">{item?.plan_name}</span>
						<span className="teriff-buy-up-text">{item?.cost} Credits</span>
					</div>
				</div>
				<div
					className="teriff-card-down"
					style={{ backgroundColor: item?.plan_color }}
				>
					<div className="teriff-card-space">
						<span className="teriff-title">₹ {item?.cost}.00</span>
						<span
							onClick={() => generateOrderid(item?.cost, item?.plan_name)}
							className="teriff-buy-text"
						>
							BUY
						</span>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.png")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="about_card">
					<div className="about_container" style={{ padding: 0 }}>
						<div
							className="table-container"
							style={{ backgroundColor: "#fff" }}
						>
							<div className="page-section">
								<span>Register Plans</span>
							</div>
							{isLoading && <Loader />}
							<div className="teriff-plan-container">
								{teriffPlan.map((item, index) => (
									<ItemContent item={item} index={index} />
								))}
							</div>

							<div style={{ height: 1, backgroundColor: "#D9D9D9" }} />
							<div className="teriff-plan-container-card">
								{teriffData?.map((item, index) => (
									<PlanCard item={item} index={index} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationTarrifPlan;
