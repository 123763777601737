import React from "react";
import styles from "../styles/UseCases.module.css";

function UseCases({
  heading = "",
  data = []
}) {
  return (
    <div className={styles.UseCases}>
      <div className={styles.innerWrapper}>
        <div className={styles.headingWrapper}>
          <span className={styles.heading}>{heading}</span>
        </div>
        <div className={styles.cardContainer}>
          {data.map(({ title, content }, i) => (
            <div className={styles.card} key={i}>
              <div className={styles.iconContainer}>
                <i class="fa fa-quote-left" />
              </div>
              <div className={styles.tileandContentContainer}>
                <h3 className={styles.cardTitle}>{title}</h3>
                <p className={styles.cardContent}>
                  {content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UseCases;
