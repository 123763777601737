import {
	TOKEN_FUNCTION,
	USERINFO,
	NAV_BAR,
	Language,
	SettingType,
	PictureType,
	TeriffData,
	TeriffPlan,
	PaymentHistoryAction,
	ModalManager,
	AdminInfo
} from "../ActionTypes";

export const setTokenFunction = (data) => {
	return { type: TOKEN_FUNCTION, payload: data };
};
export const setNavbar = (data) => {
	return { type: NAV_BAR, payload: data };
};
export const setUserInfo = (data) => {
	return { type: USERINFO, payload: data };
};
export const setSettingData = (data) => {
	return { type: SettingType, payload: data };
};
export const setProfilePicture = (data) => {
	return { type: PictureType, payload: data };
};
export const setTeriffData = (data) => {
	return { type: TeriffData, payload: data };
};
export const setTeriffPlan = (data) => {
	return { type: TeriffPlan, payload: data };
};
export const setPaymentHistoryData = (data) => {
	return { type: PaymentHistoryAction, payload: data };
};
export const setLanguage = (data) => {
	console.log(data, "data");
	return { type: Language, payload: data };
};
export const setModalData = (data) => {
	console.log(data, "data");
	return { type: ModalManager, payload: data };
};
export const setAdminInfo = (data)=>{
	return {type:AdminInfo, payload: data}
}
