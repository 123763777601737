import { useEffect, useState } from 'react';
import { personsImgs } from '../../utils/images';
import { navigationLinks } from '../../data/data';
import "./Sidebar.css";
import { useContext } from 'react';
import { SidebarContext } from '../../context/sidebarContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const [sidebarClass, setSidebarClass] = useState("");
  const { isSidebarOpen } = useContext(SidebarContext);
  const navigation = useNavigate()
  const location = useLocation();

  useEffect(() => {
    if(isSidebarOpen){
      setSidebarClass('admin-sidebar-change');
    } else {
      setSidebarClass('');
    }
  }, [isSidebarOpen]);

  const handleNavClick = (title, link)=>{
    switch (title) {
      case "Logout":
        localStorage.clear()
        window.location.reload()
        break;
      default:
        navigation(link)
        break;
    }
  }

  return (
    <div className={ `admin-sidebar ${sidebarClass}` }>
      <div className="admin-user-info">
          <div className="admin-info-img img-fit-cover">
              <img src={ personsImgs.person_two } alt="profile image" />
          </div>
          <span className="admin-info-name">alice-doe</span>
      </div>

      <nav className="admin-navigation">
          <ul className="admin-nav-list">
            {
              navigationLinks.map((navigationLink) => (
                <li className="admin-nav-item" key = { navigationLink.id } onClick={()=>handleNavClick(navigationLink.title, navigationLink.link)}>
                  <a href="#" className={ `admin-nav-link ${location.pathname === navigationLink.link ? 'active' : ''}` }>
                      <img src={ navigationLink.image } className="admin-nav-link-icon" alt = { navigationLink.title } />
                      <span className="admin-nav-link-text">{ navigationLink.title }</span>
                  </a>
                </li>
              ))
            }
          </ul>
      </nav>
    </div>
  )
}

export default Sidebar
