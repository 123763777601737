import React from "react";

const Loader = () => {
	return (
        <div className="ld-ripple-wrapper">
		<div class="ld-ripple">
			<div></div>
			<div></div>
		</div>
        </div>

	);
};

export default Loader;
