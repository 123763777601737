import React from "react";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import MainBridge from "./MainBridge.js";
const App = () => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<MainBridge />
			</PersistGate>
		</Provider>
	);
};

export default App;
