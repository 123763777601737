import React, { useCallback, useEffect, useState } from "react";
import "./PageContainer.css";
import "./TeriffPlans.css";
import cashfree from "../../../util";
import { v4 as uuidv4 } from "uuid";
import { GetTariffplans } from "../../API";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
	setTeriffData,
	setTeriffPlan,
} from "../../../redux/Actions/UserAction";
import { toast } from "react-hot-toast";
const TeriffPlans = () => {
	const { teriffPlan, teriffData, userInfo } = useSelector(
		(state) => state.user
	);
	console.log(userInfo);
	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [teriffName, setTeriffName] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		GetPlan();
	}, []);

	const GetPlan = useCallback(async () => {
		setLoading(true);
		let token = localStorage.getItem("key");
		try {
			GetTariffplans(token).then((response) => {
				if (response?.response) {
					const outputArray = Object.entries(
						response?.data?.credit_charges
					).map(([name, value], index) => ({
						id: index + 1,
						name,
						value,
					}));
					const res = outputArray?.map((item, index) => {
						return {
							id: item.id,
							name: item.name,
							value: item.value,
						};
					});
					dispatch(setTeriffPlan(res));
					dispatch(setTeriffData(response.data?.traffic_plans));
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, []);

	const handleRedirect = (session_id) => {
		let paymentOptions = {
			paymentSessionId: session_id,
		};
		cashfree.checkout(paymentOptions).then((result) => {
			console.log(result);
		});
	};
	useEffect(() => {
		const url = window.location.href;

		const orderId = url.split("orderId=")[1];
		if (orderId) {
			getPaymentDetails(orderId);
		}
	}, []);
	const getPaymentDetails = (orderId) => {
		const token = localStorage.getItem("key");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", `Token ${token}`);
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({ orderid: orderId }),
		};
		fetch(`getpaymentdata/`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				const { order_status, order_note, created_at, customer_details } =
					result?.order_info || {};
				console.log(result, "result===> PaymentData");
				handlePayments(result?.["payment-info"][0], {
					...customer_details,
					order_note: order_note,
					created_at: created_at,
				});
				if (order_status === "PAID") {
					toast.success("Payment successfully done.", {
						duration: 10000,
					});
				} else if (order_status === "FAILED") {
					toast.error("Payment failed", {
						duration: 10000,
					});
				} else if (order_status === "USER_DROPPED") {
					toast.error("Payment cancelled", {
						duration: 10000,
					});
				} else if (order_status === "PENDING") {
					toast.error("Payment pending", {
						duration: 10000,
					});
				}
			})
			.catch((error) => console.log("error==> PaymentData", error));
	};

	const handlePayments = async (data, customer_details) => {
		try {
			let paymentOption = {
				transc_id: data?.order_id,
				ref_id: data?.cf_payment_id,
				mode: data?.payment_group,
				status: data?.payment_status,
				amount: data?.order_amount,
				init_time: customer_details?.created_at,
				product_info: customer_details?.order_note,
				firstname: customer_details?.customer_name,
				email: customer_details?.customer_email,
				phone: customer_details?.customer_phone,
				bank_ref_id: data?.bank_reference,
				paid_amount: data?.payment_amount,
				payment_completion_time: data?.payment_completion_time,
				payment_currency: "INR",
				request_type: 0,
				payment_method: data?.payment_group,
				error_details: "error_details",
			};
			const token = localStorage.getItem("key");
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Authorization", `Token ${token}`);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(paymentOption),
			};
			const result = await fetch(
				"https://apisyn.certifi.biz/verifyandagreement/",
				requestOptions
			);
			console.log(result, "result=> handlePayments function");
			if (result.ok) {
				toast.success("Notified to server.", {
					duration: 10000,
				});
			} else {
				toast.error(
					"There are some internal issues. Please connect with the internal team",
					{
						duration: 10000,
					}
				);
			}
		} catch (error) {
			toast.error(
				"There are some internal issues. Please connect with the internal team",
				{
					duration: 10000,
				}
			);
		}
	};

	const generateOrderid = async (amnt, plan) => {
		const token = localStorage.getItem("key");
		const uniqueId = uuidv4();
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", `Token ${token}`);

		const raw = JSON.stringify({
			orderid: uniqueId?.toString(),
			currency: "INR",
			ordernote: plan,
			fname: userInfo?.first_name,
			mailid: userInfo?.email,
			phone: userInfo?.mob,
			cost: amnt,
			return_url: `https://main.certifi.biz/teriff-plans?orderId=${uniqueId}`,
			notify_url: "https://main.certifi.biz/notify/",
			req_type: "0",
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		const resp = await fetch(
			"https://apisyn.certifi.biz/beforePayment/",
			requestOptions
		);
		const result = await resp.text();
		handleRedirect(JSON.parse(result).id);
	};

	const ItemContent = ({ item, index }) => {
		if (item.name == "chitrdfr") {
			return;
		}
		return (
			<div key={index} className="teriff-plan-value">
				<span>
					{item.name == "maildfr"
						? "mail"
						: item.name == "smsdfr"
						? "sms"
						: item.name == "reverificationdfr"
						? "reverification"
						: item.name == "form65bdfr"
						? "form65B"
						: item.name}
				</span>
				<span>{item.value}</span>
			</div>
		);
	};
	const PlanCard = ({ item, index }) => {
		return (
			<div key={index} className="teriff-card-wrapper">
				<div
					className={`teriff-card-up`}
					style={{ backgroundColor: item?.plan_color_secondary }}
				>
					<div className="teriff-card-space">
						<span className="teriff-title">{item?.plan_name}</span>
						<span className="teriff-buy-up-text">{item?.cost} Credits</span>
					</div>
				</div>
				<div
					className="teriff-card-down"
					style={{ backgroundColor: item?.plan_color }}
				>
					<div className="teriff-card-space">
						<span className="teriff-title">₹ {item?.cost}.00</span>
						<span
							onClick={() => generateOrderid(item?.cost, item?.plan_name)}
							className="teriff-buy-text"
						>
							BUY
						</span>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="table-container">
			<div className="page-section">
				<span>Tariff Plans</span>
			</div>
			{isLoading && <Loader />}
			<div className="teriff-plan-container">
				{teriffPlan.map((item, index) => (
					<ItemContent item={item} index={index} />
				))}
			</div>

			<div style={{ height: 1, backgroundColor: "#D9D9D9" }} />
			<div className="teriff-plan-container-card">
				{teriffData?.map((item, index) => (
					<PlanCard item={item} index={index} />
				))}
			</div>
		</div>
	);
};

export default TeriffPlans;
