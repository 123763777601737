import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import { PrivacyData } from "./components/constant";
import PrivacyPolicy from "./components/privacypolicy";


const DashboardPrivacyPolicy = () => {
  return (
    <PageWrapper>
      <PrivacyPolicy policy={PrivacyData} />
    </PageWrapper>
  );
};

export default DashboardPrivacyPolicy;
